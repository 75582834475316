<mat-card class="logout-mat-card">
  <div class="container">
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <mat-card class="content logout">
          <h1 mat-card-title>
            {{'STANDARD_LIBRARY.TEXT_THANK_YOU' | translate}}
          </h1>
          <mat-card-content class="logout-content" [routerLink]="['/login']" routerLinkActive="active">
            <div class="row">
              <div class="col-sm-12">
                <span style="font-weight: bold">
                  {{'PROQ.GLOBAL.THANK_YOU_FOR_USING' | translate}}<br><br>
                {{'PROQ.GLOBAL.YOU_HAVE_BEEN_LOGGED_OUT' | translate}}
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</mat-card>
  