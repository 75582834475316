import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../shared/patient';
import { Study } from '../../shared/study';
import { Site } from '../../shared/site';
import { ProqDataService } from '../../services/data.service';
import {SlideInOutAnimation } from '../../shared/animations';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-patient',
  templateUrl: './select-patient.component.html',
  styleUrls: ['./select-patient.component.scss'],
  animations: [SlideInOutAnimation]
})

export class SelectPatientComponent implements OnInit {

  patients: Patient[];
  errMess: String;
  studyid: number;
  siteid: number;
  
  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  numberpatients: number;

  MyPatientSelect: FormControl = new FormControl();
  filteredPatients: Patient[];
  patientPanelOptions: String[];
  filteredOptions: String[];
  sys_messages: String[];

  private sub: any;

  constructor(private data: ProqDataService,
              private patientService: PatientService,
              private router: Router,
              public translate: TranslateService) {

                translate.stream(['PROQ.GLOBAL.A','STANDARD_LIBRARY.TEXT_PATIENT']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['STANDARD_LIBRARY.TEXT_PATIENT']);

                });
              }

  ngOnInit() {

    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    
    
   // this.data.setModuleElement('a PATIENT');

    this.data.currentStudy.subscribe(selectedstudy => {
      this.thisstudy = selectedstudy; 
      if (this.thisstudy.StudyId == undefined) { 
        this.thisstudy = this.data.getSessionValue('study');
      }
    });

    this.data.currentSite.subscribe(selectedsite => {
      this.thissite = selectedsite; 
      if (this.thissite.SiteId == undefined) { 
        this.thissite = this.data.getSessionValue('site');
      }
    });

    this.data.currentPatient.subscribe(selectedpatient => {
      this.thispatient = selectedpatient; 
      if (this.thispatient == undefined) { 
        this.thispatient = this.data.getSessionValue('patient');
      }

    });
    
    // Protection statements in case something went wrong with the session storage
    if (this.thisstudy == null) { this.router.navigate([`/select/study`]);}
    else if (this.thissite == null) {this.router.navigate([`/select/site`]);} 

    this.studyid = this.thisstudy.StudyId;
    this.siteid = this.thissite.SiteId;

    this.patientService.getPatients(this.studyid,this.siteid)
    .subscribe((patients) => {
      this.patients = patients; 
      this.numberpatients = patients.length;
      this.filteredPatients = this.patients;

      this.patientPanelOptions = [];
      for (var i=0; i< this.patients.length; i++) {
        this.patientPanelOptions.push(this.patients[i].Patient);
      }
    },
      errmess => this.errMess = <any>errmess);

    if (this.errMess != null) {
      console.log(this.errMess);
    }
    this.data.setPatients(this.patients);

    this.MyPatientSelect.valueChanges
    .subscribe(data => {
      if (data != null && data != undefined && (typeof data == "string")) {
        this.filteredPatients = this.filterBy(data);
        this.filteredOptions = this._filter(data);
      }
    });
  }

  private _filter(value: string): String[] {
    const filterValue = value.toLowerCase();
  
    return this.patientPanelOptions.filter(option => option.includes(filterValue));
  }

  filterBy(prop: string) {
    return this.patients.filter(item => item.Patient.toLowerCase().indexOf(prop.toLowerCase()) > -1);
  }




  newPatient(pPatient: Patient) {
    this.thispatient = pPatient;
    this.thispatient.SitePatientId = this.thissite.Site + '-' + pPatient.Patient;

    this.data.setPatient(this.thispatient);
  }


}
