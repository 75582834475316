import { Component, OnInit } from '@angular/core';
import { CookiesService } from '../../services/cookies.service';
import { ProqDataService } from '../../services/data.service';
import {EventService} from '../../services/event.service';

@Component({
  selector: 'app-patient-farewell',
  templateUrl: './patient-farewell.component.html',
  styleUrls: ['./patient-farewell.component.scss']
})
export class PatientFarewellComponent implements OnInit {


  cookiemsg: any[];
  errMess: String;
  iserror: boolean = false;

  constructor(private cookieService: CookiesService,
              private data: ProqDataService,
              private eventService: EventService) { }

  ngOnInit() {
    this.data.setHeaderDisplay(false);

    this.iserror = this.data.getSessionValue("submit_error");
    this.cookieService.logoutCookies()
    .subscribe((cookiemsg) => {this.cookiemsg = cookiemsg;}, errmess => this.errMess = <any>errmess);
    this.data.setSessionValue('module','goodbye');

  }
}


