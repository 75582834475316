<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"> -->
<div class="langselectdiv">
    <mat-form-field appearance="fill">
        <mat-icon  [ngStyle]="{'color':'white'}">language</mat-icon>
        <mat-select class="language-dropdown" [(ngModel)]="langSelect" (selectionChange)="changepage($event)" >
            <mat-option *ngFor="let lang of study_languages" [value]="lang">
                {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + lang | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<h2 mat-dialog-title>{{'PROQ.RC_CONFIRM.TITLE' | translate | titlecase}}</h2>

<mat-dialog-content class="confirm-dialog">
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'STANDARD_LIBRARY.TEXT_STUDY' | translate | titlecase}}: </mat-label>
        </div>
        <div class="col-xs-9">
            {{thisstudy.StudyTitle}} ({{thisstudy.StudyCode}})
        </div>
    </div>
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'STANDARD_LIBRARY.TEXT_SITE' | translate | titlecase}}: </mat-label>
        </div>
        <div class="col-xs-9">
                {{thissite.SiteName}} ({{thissite.Site}})
        </div>
    </div>
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'STANDARD_LIBRARY.TEXT_PATIENT' | translate | titlecase}}: </mat-label>
        </div>
        <div class="col-xs-9">
            {{thispatient.StudySite}}-{{thispatient.Patient}}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'STANDARD_LIBRARY.TEXT_VISIT' | translate | titlecase}}: </mat-label>
        </div>
        <div class="col-xs-9">
            {{thisvisit.VisitDescription}} ({{thisvisit.VisitName}})
        </div>
    </div>
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'STANDARD_LIBRARY.TEXT_FORMS' | translate | titlecase}}: </mat-label>
        </div>
        <div class="col-xs-9">
            <div *ngFor="let thisform of thisforms; let i = index">{{thisform.FormDescription}}{{i == thisformcount-1?'':','}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-3">
            <mat-label>{{'PROQ.GLOBAL.SELECTED_LANGUAGE' | translate}}:</mat-label>
        </div>
        <div class="col-xs-9">
            {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + this.translate.currentLang | translate}}
        </div>
    </div>
    <div class = "row">
        <div class="col-xs-3"></div>
        <div class="col-xs-9 errorMessage" *ngIf="lang_err">{{'STANDARD_LIBRARY.LANG_NOT_SUPPORTED'|translate}}</div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <br>
            <b>{{'PROQ.RC_CONFIRM.TEXT_CERTIFY' | translate}}</b>
            <br>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="col-xs-6 col-sm-3">
        <button class="mat-raised-button cancel-button" (click)="close()">{{'STANDARD_LIBRARY.BUTTON.CANCEL' | translate}}</button>
    </div>
    <div class="col-sm-3"></div>
    <div class="col-xs-6 col-sm-3">
        <button class="mat-raised-button mat-primary confirm-button"(click)="confirm()"  [disabled]="lang_err" >{{'STANDARD_LIBRARY.BUTTON.CONFIRM' | translate}}</button>
    </div>
</mat-dialog-actions>
