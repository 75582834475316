import { trigger, state, style, transition,
    animate, group, query, stagger, keyframes, animateChild
} from '@angular/animations';

export const SlideInOutAnimation = [

    trigger('animationOption3', [
        transition(':enter', [
          style({opacity: 0 })
        ]),
        transition(':leave',[])
    ]),

    trigger('animationOption2', [
        transition(':enter', [
          style({opacity: 0 }),
          animate('700ms ease-in', style({opacity: 1}))
        ])
    ]),

    trigger('slideInOutAnimation', [
        transition(':enter', [
            style({
            transform: 'translateX(200%)',
            opacity: 0
            }),
            animate('.5s ease-in-out', style({
            transform: 'translateX(0)',
            opacity: 1
            }))
        ]),
        transition(':leave', [
            animate('.5s ease-in-out', style({
            transform: 'translateX(200%)',
            opacity: 0
            }))
        ])
      ])
    ];
    

    export const slideInAnimation =
        trigger('routeAnimations', [
            transition('SelectStudyPage => SelectSitePage', [
                query('.selected-study, :enter', [
                    style({
                        transform: 'translateX(200%)',
                        opacity: 0
                        }),
                        animate('.5s ease-in-out', style({
                        transform: 'translateX(0)',
                        opacity: 1
                        }))
                ]),
            ]),
            transition('SelectSitePage => SelectPatientPage', [
                query('.selected-site, :enter', [
                    style({
                        transform: 'translateX(200%)',
                        opacity: 0
                        }),
                        animate('.5s ease-in-out', style({
                        transform: 'translateX(0)',
                        opacity: 1
                        }))
                ]),
            ]),
            transition('SelectPatientPage => SelectVisitPage', [
                query('.selected-patient, :enter', [
                    style({
                        transform: 'translateX(200%)',
                        opacity: 0
                        }),
                        animate('.5s ease-in-out', style({
                        transform: 'translateX(0)',
                        opacity: 1
                        }))
                ]),
            ]),
            transition('SelectVisitPage => SelectFormPage', [
                query('.selected-visit, :enter', [
                    style({
                        transform: 'translateX(200%)',
                        opacity: 0
                        }),
                        animate('.5s ease-in-out', style({
                        transform: 'translateX(0)',
                        opacity: 1
                        }))
                ]),
            ]),



            // SITE PAGE TRANSITIONS
            // Got to Study Page
            transition('SelectSitePage => SelectStudyPage, SelectPatientPage => SelectStudyPage, SelectVisitPage => SelectStudyPage', [
                query('.study-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),

            // PATIENT PAGE TRANSITIONS
            // Go to Site Page
            transition('SelectPatientPage => SelectSitePage', [
                query('.site-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ])
            ]),
            transition('SelectPatientPage => SelectStudyPage', [
                query('.study-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ])
            ]),

            // VISIT PAGE TRANSTIONS
            // Go to Patient Page
            transition('SelectVisitPage  => SelectPatientPage', [
                query('.patient-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
            // Go to Site Page
            transition('SelectVisitPage  => SelectSitePage', [
                query('.site-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
            // Go to Study Page
            transition('SelectVisitPage  => SelectSitePage', [
                query('.study-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),

            // FORM PAGE TRANSITIONS
            // Go to Visit Page
            transition('SelectFormPage => SelectVisitPage', [
                query('.visit-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
            transition('SelectFormPage => SelectPatientPage', [
                query('.patient-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
            transition('SelectFormPage => SelectSitePage', [
                query('.site-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
            transition('SelectFormPage => SelectStudyPage', [
                query('.study-leave, :leave', [
                    animate('.5s ease-in-out', style({
                        transform: 'translateX(200%)',
                        opacity: 0
                    }))
                ]),
            ]),
           

  ]);



  export const Bullseye = [

    trigger('bullseye', [
        state('false', style({ "background-color": "#004f71" })),
        state('true', style({"background-image": "repeating-radial-gradient(circle at 50% 50%, #004f71, #009cde 120%)"})),
        transition('false => true', animate('500ms ease-in'))
      ]),
    ];

/*
 repeating-radial-gradient(
      circle at 0 0, 
      #eee,
      #ccc 50px
      */