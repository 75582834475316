import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Study } from '../shared/study';
import { Site } from '../shared/site';
import { Patient } from '../shared/patient';
import { Visit } from '../shared/visit';
import { ProqMode } from '../shared/mode';
import { Form } from '../shared/form';
import { User } from '../shared/user';

import { AngularWebStorageModule } from 'angular-web-storage';
import { SessionStorageService, SessionStorage, LocalStorage, LocalStorageService } from 'angular-web-storage';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProqDataService {

  KEY = 'value';
  value: any = null;

  private studySource = new BehaviorSubject(new Study);
  currentStudy = this.studySource.asObservable();

  private siteSource = new BehaviorSubject(new Site);
  currentSite = this.siteSource.asObservable();

  private patientSource = new BehaviorSubject(new Patient);
  currentPatient = this.patientSource.asObservable();

  private patientsSource = new BehaviorSubject<any[]>([]);
  currentPatients = this.patientsSource.asObservable();

  private visitSource = new BehaviorSubject(new Visit);
  currentVisit = this.visitSource.asObservable();

  private proqModeSource = new BehaviorSubject(new ProqMode);
  currentMode = this.proqModeSource.asObservable();

  private formsSource = new BehaviorSubject<any[]>([]);
  currentForms = this.formsSource.asObservable();

  private studiesSource = new BehaviorSubject<any[]>([]);
  currentStudies = this.studiesSource.asObservable();

  private sitesSource = new BehaviorSubject<any[]>([]);
  currentSites = this.sitesSource.asObservable();

  private formSource = new BehaviorSubject(new Form);
  currentForm = this.formSource.asObservable();

  private userSource = new BehaviorSubject(new User);
  currentUser = this.userSource.asObservable();

  private moduleSource = new BehaviorSubject(new String);
  currentModuleElement = this.moduleSource.asObservable();

  private accessTimeSource = new BehaviorSubject(new Date);
  currentAccessTime = this.accessTimeSource.asObservable();

  private headerDisplaySource = new BehaviorSubject(new Boolean);
  currentHeaderDisplay = this.headerDisplaySource.asObservable();

  private logoutDisplaySource = new BehaviorSubject(new Boolean);
  currentLogoutDisplay = this.logoutDisplaySource.asObservable();

  private supportedLanguagesSource = new BehaviorSubject<any[]>([]);
  currentLanguages = this.supportedLanguagesSource.asObservable();

  private allLanguagesSource = new BehaviorSubject<any[]>([]);
  currentAllLanguages = this.allLanguagesSource.asObservable();

  private currentLanguageSelectedSource = new BehaviorSubject(new String);
  currentLanguageSelected = this.currentLanguageSelectedSource.asObservable();

  constructor(public session: SessionStorageService,
              public local: LocalStorageService,
              public translate:TranslateService) { }

  convertText(conversion:string,pvalue:string) {
    var output: string;
    var pw: String = 'marypoppins';

    if (conversion=="encrypt") {
      output = CryptoJS.AES.encrypt( pvalue, pw).toString();
    }
    else {
      output = CryptoJS.AES.decrypt(pvalue, pw).toString(CryptoJS.enc.Utf8);
    }

    return output;

}


  setHeaderDisplay(headerDisplay: Boolean) {
    this.headerDisplaySource.next(headerDisplay);
    this.setSessionValue('header_display', headerDisplay);
  }

  setLogoutDisplay(logoutDisplay: Boolean) {
    this.logoutDisplaySource.next(logoutDisplay);
    this.setSessionValue('logout_display',logoutDisplay);
  }

  setModuleElement(thismoduleelement: String) {
    this.moduleSource.next(thismoduleelement);
    this.setSessionValue('module',thismoduleelement);
  }

  setUser(thisuser: User) {
    JSON.stringify(thisuser);
    this.userSource.next(thisuser);

    this.setSessionValue('user', thisuser);
  }

  setStudy(thisstudy: Study) {
    this.studySource.next(thisstudy);
    this.siteSource.next(new Site);
    this.patientSource.next(new Patient);
    this.visitSource.next(new Visit);
    this.formSource.next(new Form);
    this.formsSource.next([new Form])

    this.setSessionValue('study', thisstudy);
  }

  setSupportedLanguages(supportedLanguages: string[]) {
    this.supportedLanguagesSource.next(supportedLanguages);
    this.setSessionValue('supported_languages',supportedLanguages);
  }

  setAllLanguages(allLanguages: string[]) {
    this.allLanguagesSource.next(allLanguages);
    this.setSessionValue('all_languages',allLanguages);
  }

  setSite(thissite: Site) {
    this.siteSource.next(thissite);
    this.patientSource.next(new Patient);
    this.visitSource.next(new Visit);
    this.formSource.next(new Form);
    this.formsSource.next([new Form]);

    this.setSessionValue('site', thissite);
  }

  setPatient(thispatient: Patient) {
    this.patientSource.next(thispatient);
    this.visitSource.next(new Visit);
    this.formSource.next(new Form);
    this.formsSource.next([new Form]);

    this.setSessionValue('patient', thispatient);
  }


  setPatients(thispatients: Patient[]) {
    this.patientsSource.next(thispatients);
    this.visitSource.next(new Visit);

    this.setSessionValue('patients', thispatients);
  }


  setVisit(thisvisit: Visit) {
    this.visitSource.next(thisvisit);
    this.formSource.next(new Form);
    this.formsSource.next([new Form]);

    this.setSessionValue('visit', thisvisit);
  }

  setMode(thismode: ProqMode) {
    var mymode = this.getLocalValue('proq_mode');
    if (mymode != null && thismode.PatientMode == false && mymode.PatientMode == 'patient') {
      return;
    }
    else {
      // Else we will set the mode, accordingly
      this.proqModeSource.next(thismode);
      this.setLocalValue('proq_mode',thismode); // thismode.PatientMode = true for patient or false for rc
    }
  }

  setForm(thisform: Form) {
    thisform.FormSelected = true;
    let formArr: Form[] = this.formsSource.getValue();

    // Cleanup code ... array was getting first item set and then
    // preserving that ... this statement fixes it
    for (var i=0; i < formArr.length; i++) {
      if (formArr[i].ProqFormId == undefined) {
        formArr = formArr.splice(i,0);
      }
    }

    formArr.push(thisform);

    this.formsSource.next(formArr);

    this.setSessionValue('forms',formArr);
  }

  setStudies(pStudies: Study[]) {
    this.studiesSource.next(pStudies);
    this.setSessionValue('studies',pStudies);
  }

  setSites(pSites: Site[]) {
    this.sitesSource.next(pSites);
    this.setSessionValue('sites',pSites);
  }

  setForms(pForms: Form[]) {
    this.formsSource.next(pForms);

    this.setSessionValue('forms',pForms);
  }

  setCurrentForm(thisForm: Form) {
    this.formSource.next(thisForm);

    this.setSessionValue('form',thisForm);
  }

  removeForm(thisform: Form) {
    //let formArr: Form[] = this.formsSource.getValue();
    let formArr: Form[] = this.getSessionValue("forms");
    formArr.forEach((item, index) => {
        if(item.ProqFormId == undefined || item.ProqFormId === thisform.ProqFormId) { formArr.splice(index,1);}
    });
    this.formsSource.next(formArr);

    this.setSessionValue('forms',formArr);
  }

  setSessionValue(key: string, json_value: any, expired: number = 0) {
    if (json_value == null) {
      this.session.set(key,null);
    }
    else {
      var value = JSON.stringify(json_value);
      var envalue = this.convertText("encrypt",value)
      this.session.set(key, envalue);
    }
  }

  setLocalValue(key: string, json_value: any, expired: number = 0) {
    var value = JSON.stringify(json_value);
    var envalue = this.convertText("encrypt",value)
    this.local.set(key, envalue);
  }


  removeSessionValue(key: string) {
      this.session.remove(key);
  }

  getSessionValue(key: string): any
  {
      if (this.session.get(key) == null) { return null; };
      var devalue = this.convertText("decrypt",this.session.get(key));

      var json_value;
      json_value = JSON.parse(devalue);

      return json_value;
  }

  clearSessionValue() {
      this.session.clear();
  }

  getLocalValue(key: string): any
  {
      if (this.local.get(key) == null) { return null; };
      var devalue = this.convertText("decrypt",this.local.get(key));

      var json_value;
      json_value = JSON.parse(devalue);

      return json_value;
  }

  clearLocalValue() {
      this.local.clear();
  }


  isRC() {
    var thisvalue = this.getLocalValue('proq_mode');
    if (thisvalue != null && thisvalue.PatientMode == true) {
      return false;
    }
    else {
      return true;
    }
  }

  setAccessTimeFirstTime() {
    var mydate =  Date.now();

    this.setSessionValue('access_time',mydate);

  }

  getLastAccessTime() {
    var last_access_time = this.getSessionValue('access_time');
    if (last_access_time == null) {
      return -500;
    }
    else {

      var dur = ((Date.now()) - (last_access_time)) / 1000 / 60; // Duration in minutes
      return dur;
    }
  }

  updateAccessTime() {
    var access_time = this.getSessionValue('access_time');
    if (access_time != null) {
      var mydate =  Date.now();

      this.setSessionValue('access_time',mydate);
    }

    // Else no values are updated
  }

  clearAccessTime() {
    this.setSessionValue('access_time',null);
  }
  setCurrentLanguage(value:String){
    this.currentLanguageSelectedSource.next(value);
    this.setSessionValue('currentLanguage',value);
  }

  setAllSupportedLanguages() {
    let all_possible_languages: string[] = ["zh", "nl", "en", "fi", "fr", "de", "it", "es"];
    let all_languages: string[] = [];
    var i = 0;
    return new Promise((resolve, reject) => {
      all_possible_languages.forEach((lang) => {
        this.translate.getTranslation(lang).subscribe(values => {
          if (values['PROQ']['SUPPORTED_LANGUAGES'] == 'Y') {
            all_languages.push(lang);
            this.setAllLanguages(all_languages);
          }
          if (i == all_possible_languages.length - 1) {
            resolve(true);
          }
        },
          () => { },
          () => { i++; })

      });
    });
  }

}
