import { Injectable } from '@angular/core';
import { Configuration } from './configuration.service';
import { DataService } from './data.service';
import { CoreService } from './core.service';
import { StorageService } from './storage.service';
import { Module } from '../../models/module';
import { environment } from '../../../environments/environment';

@Injectable()
export class SecurityService {

  constructor(private dataService: DataService, private core: CoreService,
    private config: Configuration, private storage: StorageService) { }

  private modules: Array<Module>;


  getModuleAccess(key: string, access: string) {

    if (environment.name === 'local') {
      return true;
    }

    let requestedModule = this.getModule(key);

    if (requestedModule) {
      return requestedModule[access];
    }

    return false;
  }

  getModule(key: string) {

    if (environment.name === 'local') {
      return new Module();
    }

    this.modules = JSON.parse(this.storage.retrieve("modules")) as Array<Module>;;

    let requestedModule = this.modules.filter(x => x.ModuleKey === key)[0];

    return requestedModule;
  }

  getModules() {

    if (environment.name === 'local') {
      return;
    }
    const params = [];

    this.dataService.getList('user/app/' + this.config.settings.appKey + '/modules', params, true)
      .subscribe(
        modules => {

          if(modules.length === 0)
            window.location.href = '../cdn/msg/unauthorized.html';

          this.storage.store("modules", modules);
        },
        error => {
          window.location.href = '../cdn/msg/unauthorized.html';
        }
      );
  }


}
