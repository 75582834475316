import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StudyService } from '../services/study.service';
import { Study } from '../shared/study';
import { Site } from '../shared/site';
import { ProqDataService } from '../services/data.service';
import { ProqMode } from '../shared/mode';
import { SiteService } from '../services/site.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  public show_splash_screen = true;
  studies: Study[];
  errMess: String;
  myuser: String;
  thisstudy: Study;
  thismode: ProqMode = new ProqMode;
  patientmode: Boolean;
  numberofstudies: number;

  thissite: Site;
  sites: Site[];
  studyid: number;
  numbersites: number;

  constructor(private router: Router, 
              private route: ActivatedRoute,
              private studyService: StudyService,
              private siteService: SiteService,
              private data: ProqDataService ) { }

  ngOnInit() {

    this.studies = this.data.getSessionValue('studies');
    
      this.numberofstudies = this.studies.length;
      if (this.studies.length == 1) {
        this.newStudy(this.studies[0]);
        this.thisstudy = this.studies[0];
        this.studyid = this.thisstudy.StudyId;
        this.numbersites = 0;
    
        this.siteService.getSites(this.studyid)
          .subscribe((sites) => {
              this.sites = sites;
              this.data.setSites(this.sites); // Set Session Variable
              this.numbersites = this.sites.length;
              if (this.sites.length == 1) {
                this.newSite(this.sites[0]);
                this.router.navigate([`/select/patient`], { relativeTo: this.route });
              }
              else if (this.numbersites > 1) {
                this.router.navigate([`/select/site`], { relativeTo: this.route });
              }
            },
            errmess => this.errMess = <any>errmess);

      }
      else {
        this.router.navigate([`/select/study`], { relativeTo: this.route });
      }
  }

  newStudy(pStudy: Study) {

    this.data.setStudy(pStudy);
  }

  
  newSite(pSite: Site) {
    this.data.setSite(pSite);
  }


  

}


