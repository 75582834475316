import { Component, OnInit } from '@angular/core';
import { VisitService } from '../../services/visit.service';
import { Visit } from '../../shared/visit';
import { Study } from '../../shared/study';
import { Site } from '../../shared/site';
import { Patient } from '../../shared/patient';
import { ProqDataService } from '../../services/data.service';
import { SlideInOutAnimation } from '../../shared/animations';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-visit',
  templateUrl: './select-visit.component.html',
  styleUrls: ['./select-visit.component.scss'],
  animations: [SlideInOutAnimation]
})
export class SelectVisitComponent implements OnInit {

  visits: Visit[];
  errMess: String;
  studyid: number;
  siteid: number;
  patientid: number;
  visitupperbounddt: string;
  visitlowerbounddt: string;

  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  thisvisit: Visit;
  sys_messages: String[];

  constructor(private data: ProqDataService, 
              private visitService: VisitService,
              private router: Router,
              public translate: TranslateService) {

                translate.stream(['PROQ.GLOBAL.A','STANDARD_LIBRARY.TEXT_VISIT']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['STANDARD_LIBRARY.TEXT_VISIT']);

                });
              }
              

  ngOnInit() {
    this.data.setHeaderDisplay(true);

    this.data.currentStudy.subscribe(selectedstudy => {
      this.thisstudy = selectedstudy;
      if (this.thisstudy.StudyId == undefined) { 
        this.thisstudy = this.data.getSessionValue('study');
      }
    });

    this.data.currentSite.subscribe(selectedsite => {
      this.thissite = selectedsite;
      if (this.thissite.SiteId == undefined) { 
        this.thissite = this.data.getSessionValue('site');
      }
    });

    this.data.currentPatient.subscribe(selectedpatient => {
      this.thispatient = selectedpatient;
      if (this.thispatient.PatientId == undefined) { 
        this.thispatient = this.data.getSessionValue('patient');
      }
    });

    this.data.currentVisit.subscribe(selectedvisit => {
      this.thisvisit = selectedvisit;
      if (this.thisvisit.VisitId == undefined) { 
        this.thisvisit = this.data.getSessionValue('visit');
      }
    });

    // Protection statements in case something went wrong with the session storage
    if (this.thisstudy == null) { this.router.navigate([`/select/study`]);}
    else if (this.thissite == null) {this.router.navigate([`/select/site`]);} 
    else if (this.thispatient == null) {this.router.navigate([`/select/patient`]);}

        

    this.studyid = this.thisstudy.StudyId;
    this.siteid = this.thissite.SiteId;
    this.patientid = this.thispatient.PatientId;


    this.visitService.getVisits(this.studyid,this.siteid,this.patientid)
      .subscribe((visits) => {this.visits = visits;},
        errmess => this.errMess = <any>errmess);
  }

  NewVisit(pVisit: Visit) {
    this.thisvisit = pVisit;
    this.data.setVisit(pVisit);
  }






}



