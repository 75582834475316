<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="site-header" *ngIf="header_display == true">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4 col-xs-5">
        <img alt="Abbott" src="assets/img/abbott-logo-white-with-text.png"/>
      </div>
      <div class="col-sm-4 col-xs-3 text-center app-title">
        PRO-Q
      </div>
      <div class="col-sm-4 col-xs-4 text-right">
        <ul>
          <li class="loader">
            <a>
              <i class="fas fa-sync-alt fa-spin" *ngIf="working === true"></i>
            </a>
          </li>
          <li>
            <div class="langselectdiv">
              <mat-form-field appearance="fill" *ngIf="!patient_mode">
                <mat-icon  [ngStyle]="{'color':'white'}">language</mat-icon>
                <mat-select class="language-dropdown" [(ngModel)]="langSelect" (selectionChange)="changepage($event)" >
                  <mat-option *ngFor="let lang of all_languages" [value]="lang">
                    {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + lang | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--<div class="langselectdiv" *ngIf="patient_mode">
              <mat-form-field appearance="fill">
                <mat-icon  [ngStyle]="{'color':'white'}">language</mat-icon>
                <mat-select class="language-dropdown" (selectionChange)="changepage($event)" >
                  <mat-option *ngFor="let lang of study_languages" [value]="lang">
                    {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + lang | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>-->
          </li>
          <li class="icon-link">
            <a class="white pointer" (click)="signout()">
              <i class="fas fa-sign-out-alt"></i>
              <div class="title">{{'STANDARD_LIBRARY.TEXT_LOGOUT' | translate | titlecase}}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="moduleElement">
      <div class="col-sm-12" class="welcome">
          {{'STANDARD_LIBRARY.TEXT_SELECT' | translate}} {{moduleElement}}:
      </div>
  </div>
  </div>
</div>
