<mat-card class="timeout-mat-card">
  <div class="container">
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <mat-card class="content timeout">
          <h1 mat-card-title>
            {{'STANDARD_LIBRARY.TEXT_TIMEOUT' | translate | titlecase}}
          </h1>
          <mat-card-content class="timeout-content" [routerLink]="['/login']" routerLinkActive="active">
            <div class="row">
              <div class="col-sm-12">
                <span style="font-weight: bold">
                  {{'PROQ.GLOBAL.LOGGED_OUT_DUE_TO_INACTIVITY' | translate}}.
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</mat-card>
