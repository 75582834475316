import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Study } from '../shared/study';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  private baseUrl: string = 'proq_api';
  private study: Study;

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService) { }


  getStudies() : Observable<Study[]> {

    var thisjson = this.dataService.getList(this.baseUrl + '/api')
        .pipe(catchError(this.processHTTPMsgService.handleError));
    
    return thisjson;
  }

}
