import { Component, ViewEncapsulation } from '@angular/core';
import { CoreService, SecurityService, LoggingService } from '../template-shared/services/core';
import { TranslateService } from '@ngx-translate/core';
import { ProqDataService } from './services/data.service';
import { EventService} from './services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  private working = this.core.getWorking();
  browserLang: string = '';
  matched: boolean = false;
  all_languages: string[] = [];
  constructor(private core: CoreService, private security: SecurityService, private logging: LoggingService,
              public translate: TranslateService, private data: ProqDataService,
              private eventService: EventService) {

    var allLanguages = this.data.getSessionValue("all_languages");

    translate.addLangs(allLanguages);
    translate.use('en');

    this.core.workingUpdated.subscribe(
      working => {
        this.working = working;
      }
    )

    // Get list of languages from json configuration PROQ.SUPPORTED_LANGUAGES variable
    this.browserLang = this.translate.getBrowserCultureLang();
    this.matched = false;
    var allLanguages = this.data.getSessionValue("all_languages");

    allLanguages.forEach((lang) => {
      if (this.browserLang == lang && this.matched==false) {
        this.matched = true;
      }
    }
    );

    var sessionValueCurrentLanguage = this.data.getSessionValue('currentLanguage');
    if (sessionValueCurrentLanguage) {
      this.data.setCurrentLanguage(sessionValueCurrentLanguage);
      translate.getTranslation(sessionValueCurrentLanguage).subscribe(values => { });
    }
    else if (this.matched) {
      this.data.setCurrentLanguage(this.browserLang);
      translate.getTranslation(this.browserLang).subscribe(values => { });

    }
    else {
      this.data.setCurrentLanguage('en');
      // Default to English
      translate.getTranslation('en').subscribe(values => { });
    }


  }

  ngOnInit() {
    //var baseUrl = window.location.origin;
    //if (!baseUrl.includes('studyedc')) {
    //  this.security.getModules();
    //}
    //this.logging.createLoginEvent();

  }

  signout(): void {
    this.logging.createLogoutEvent();
    // window.close();
  }
}




