import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ConfirmData {
  logout: string;
}


@Component({
  selector: 'app-dialog-logout-confirm',
  templateUrl: './dialog-logout-confirm.component.html',
  styleUrls: ['./dialog-logout-confirm.component.scss']
})
export class DialogLogoutConfirmComponent implements OnInit {

  constructor( public dialog: MatDialog, 
              public dialogRef: MatDialogRef<DialogLogoutConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) {}


  ngOnInit() {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}


