<div class="container-fluid" style="margin-left: -30px; margin-right: 30px; height:100%">
    <div class="row no-gutters align-items-stretch" >
      <div class="col-12 col-sm-6 col-md-8 d-flex align-items-stretch">
        <div class="card holder-lft">
          <div class="p-2" *ngIf="numbersites > 6">
            <div class="filter-sites">
              <form class="site-select">
                <mat-form-field>
                  <input type="text" placeholder="{{'PROQ.RC_GENERIC.FILTER_SITES' | translate}}" matInput [matAutocomplete]="auto"  [formControl]="MySiteSelect">
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6" *ngFor="let thissite of filteredSites" >
            <mat-card class="site" (click)="newSite(thissite)" [routerLink]="['/select/patient']" routerLinkActive="active">
              <mat-card-title><span class="fa fa-hospital"></span> {{thissite.SiteName}}</mat-card-title>
              <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_SITE' | translate | uppercase}} {{thissite.Site}}</mat-card-subtitle>
            </mat-card>
          </div>
   
        </div> <!-- END CARD HOLDER -->
    </div> <!-- COL -->

    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
      <div class="holder-rt">    
        <mat-card class="title-selected selections">{{'PROQ.RC_GENERIC.SELECTIONS' | translate | uppercase}}</mat-card>  
        <mat-card class="mat-card-selected study selected-study study-leave" [routerLink]="['/select/study']" routerLinkActive="active">
          <mat-card-title class="title-selected"><span class="fa fa-vial"></span> {{thisstudy.StudyTitle}}</mat-card-title>
          <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_STUDY' | translate | uppercase}} {{thisstudy.StudyCode}}</mat-card-subtitle>
        </mat-card>
      </div>
    </div> <!-- MAIN COL #2 -->     

  </div> <!-- BIG ROW -->
</div> <!-- FLUID CONTAINER -->

<div fxFlex *ngIf="errMess">
  <h2>Error</h2>
  <h4>{{errMess}}</h4>
</div>
