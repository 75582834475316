import { Component, OnInit } from '@angular/core';
import { CookiesService } from '../services/cookies.service';
import { ProqDataService } from '../services/data.service';
import {EventService} from '../services/event.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {

  cookiemsg: any[];
  errMess: String;

  constructor(private cookieService: CookiesService,
              private data: ProqDataService,
              private eventService: EventService) { }

  ngOnInit() {
    this.data.setHeaderDisplay(false);
    this.eventService.add_event("TIMEOUT","Timeout User", "TIMEOUT",this.data.getSessionValue("user"));

    this.cookieService.logoutCookies()
    .subscribe((cookiemsg) => {this.cookiemsg = cookiemsg;}, errmess => this.errMess = <any>errmess);

  }

}


