export class Module {
  ModuleId: number;
  ModuleKey: string;
  Name: string;
  Description: string;
  Browse: boolean;
  Delete: boolean;
  Update: boolean;
  Insert: boolean;
}
