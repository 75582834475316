import { Component, OnInit } from '@angular/core';
import { ProqDataService } from '../services/data.service';
import {Router,  ActivatedRoute} from '@angular/router';
import { ProqMode } from '../shared/mode';
import { CookiesService } from '../services/cookies.service';
import {EncryptService} from '../services/encrypt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CheckstudyaccessService } from '../services/checkstudyaccess.service';
import { StudyService } from '../services/study.service';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../services/event.service';

@Component({
  selector: 'app-proq-login',
  templateUrl: './proq-login.component.html',
  styleUrls: ['./proq-login.component.scss']
})
export class ProqLoginComponent implements OnInit {

  proqmode: ProqMode;
  pUsername: string;
  pPassword: string;
  msg: string;
  errMess: string;
  supportMess: string;
  loading: Boolean = false;
  langSelect: String;

  sys_messages: string[];

  all_languages: string[] = [];
  browserLang: string = '';
  matched: boolean = false;

  public loginForm: FormGroup;

  constructor(private data: ProqDataService,
              private router: Router,
              private route: ActivatedRoute,
              private cookiesService: CookiesService,
              private encryptService: EncryptService,
              private checkStudyAccessService: CheckstudyaccessService,
              private studyService: StudyService,
              private eventService: EventService,
              public translate: TranslateService) {

                translate.stream(['PROQ.ERROR.INVALID_CREDENTIALS',
                                                      'PROQ.ERROR.NO_ACCESS1',
                                                      'PROQ.ERROR.NO_ACCESS2',
                                                      'PROQ.ERROR.SYSTEM_NOT_AVAILABLE',
                                                      'PROQ.ERROR.UNEXPECTED_TECHNICAL_ISSUE',
                                                      'PROQ.LOGIN.SUPPORT']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  this.supportMess = `<div class="supportMess">${this.sys_messages['PROQ.LOGIN.SUPPORT']}</div>`;
                });

              }



  ngOnInit() {
    this.data.setHeaderDisplay(false);

    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    this.all_languages = this.data.getSessionValue('all_languages');
     this.data.currentLanguageSelected.subscribe((value)=>{
        if(value){
        this.langSelect=value;
        this.translate.use(this.langSelect.toString());
        this.translate.getTranslation(value.toString()).subscribe(values => {});
        }
      });

    this.data.setSessionValue('module','login');
  }

  changepage(ob) {
    this.data.setCurrentLanguage(ob.value);
    this.translate.use(ob.value);
    this.eventService.add_event("CHANGE_LANGUAGE","Change Language from login screen to " + ob.value, "LOGIN",this.pUsername);

  }
  onKeyUsername(event) {
    this.pUsername = event.target.value;
  }

  onKeyPassword(event) {
    this.pPassword = event.target.value;
  }

  login() {
    this.loading = true;
    this.errMess = "";

    this.pUsername = this.loginForm.controls['username'].value;
    this.pPassword = this.loginForm.controls['password'].value;

    var encryptedUsername = this.encryptService.encrypt(this.pUsername);
    var encryptedPassword = this.encryptService.encrypt(this.pPassword);


    this.cookiesService.createAuthCookies(encryptedUsername, encryptedPassword)
    .subscribe((x) => {
        this.msg = x;
        if (this.msg == 'LOGIN OK') {
          this.data.setSessionValue("user",this.pUsername);
          // Check that user has access to studies per PRO-Q
          this.studyService.getStudies()
          .subscribe((x) => {
           if (x.length == 0 ) {
             this.errMess = this.sys_messages['PROQ.ERROR.NO_ACCESS1'];
             this.loading = false;
             this.eventService.add_event("LOGIN_FAILED","No Access for " + this.pUsername, "LOGIN",this.pUsername);

           }
           else {
             this.eventService.add_event("LOGIN","User Login", "LOGIN",this.pUsername);
             this.data.setAccessTimeFirstTime();
             this.loading = false;
             this.data.setStudies(x); // Saves studies in session variable
             this.router.navigate([`/splash`]);
           }
          },
          errmess => {
            this.errMess = errmess;
            this.eventService.add_event("LOGIN_FAILED","Login failed for " + this.pUsername, "LOGIN",this.pUsername);

          });
        }
      },
      errmess => {
        this.errMess = errmess;
        if (this.errMess.includes('500')) {this.errMess = this.sys_messages['PROQ.ERROR.INVALID_CREDENTIALS']; this.loading = false;
          this.eventService.add_event("LOGIN_FAILED","Invalid login credentials for " + this.pUsername, "LOGIN",this.pUsername);
        }
        else if (this.errMess.includes('503') ||
                this.errMess.includes('404')) {
          this.errMess = this.sys_messages['PROQ.ERROR.SYSTEM_UNAVAILABLE'];
          this.loading = false;
          this.eventService.add_event("LOGIN_FAILED","System Unavailable for " + this.pUsername, "LOGIN",this.pUsername);

        }
        else if (this.errMess.includes('401')) {
          this.errMess = this.sys_messages['PROQ.ERROR.NO_ACCESS2'];
          this.eventService.add_event("LOGIN_FAILED","No Access Failure for " + this.pUsername, "LOGIN",this.pUsername);

          this.loading = false;
        }
        else {
          this.errMess = this.sys_messages['PROQ.ERROR.UNEXPECTED_TECHNICAL_ISSUE'];
          this.eventService.add_event("LOGIN_FAILED","Unexpected Technical Issue for " + this.pUsername, "LOGIN",this.pUsername);

          this.loading = false;
        }

      });

  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.loginForm.controls[controlName].hasError(errorName);
  }

}
