import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { ProqDataService } from '../services/data.service';
import { Study } from '../shared/study';
import { Site } from '../shared/site';
import { Patient } from '../shared/patient';
import { Visit } from '../shared/visit';
import { User } from '../shared/user';
import { ProqService } from '../services/proq.service';
import { slideInAnimation } from '../shared/animations';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class MainComponent implements OnInit {

 selectedStudy: Study;
 selectedSite: Site;
 selectedPatient: Patient;
 selectedVisit: Visit;
 userdetails: User;
 errMess: string;
 folderName: string = "proq";
 moduleElement: String;
 thisUserName: string;

  constructor(private router: Router, 
              private route: ActivatedRoute, 
              private data: ProqDataService,
              private proqService: ProqService) { 
    this.route.params.subscribe( params => {} );
  }

  ngOnInit() {

    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    this.data.currentStudy.subscribe(selectedstudy => this.selectedStudy = selectedstudy);
    this.data.currentSite.subscribe(selectedsite => this.selectedSite = selectedsite);
    this.data.currentPatient.subscribe(selectedpatient => this.selectedPatient = selectedpatient);
    this.data.currentVisit.subscribe(selectedvisit => this.selectedVisit = selectedvisit);
    this.data.currentModuleElement.subscribe(moduleElement => this.moduleElement = moduleElement);
    

    this.proqService.getUser()
    .subscribe((userdetails) => {
      this.userdetails = userdetails;
      this.data.setUser(this.userdetails);
      this.thisUserName = this.userdetails[0].FirstName + ' ' + this.userdetails[0].LastName;
      },
      errmess => this.errMess = <any>errmess);

    //this.router.navigate([`/select/study`]);

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }



}
