import { Component, OnInit, Inject } from '@angular/core';
import { CookiesService } from '../services/cookies.service';
import { Router, ActivatedRoute} from '@angular/router';
import { ProqDataService } from '../services/data.service';
import {DialogLogoutConfirmComponent} from '../dialog-logout-confirm/dialog-logout-confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../services/event.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  working: Boolean = false;
  cookiemsg: any[];
  errMess: String;
  header_display: Boolean;
  logout_display: Boolean = false;
  big_logout: Boolean = false;
  moduleElement: String;
  patient_mode: boolean = false;
  study_languages: string[] = [];
  all_languages:string[]=[];
  public langSelect:String = '';

  constructor(private cookieService: CookiesService,
              private router: Router,
              private data: ProqDataService,
              public dialog: MatDialog,
              public translate: TranslateService,
              private eventService: EventService) {

    }

  ngOnInit() {
    this.data.currentHeaderDisplay.subscribe(header_display => {
      this.header_display = header_display;
      if (this.header_display == undefined) {
        this.header_display = this.data.getSessionValue('header_display');
      }
    });


    this.data.currentLogoutDisplay.subscribe(logout_display => {
      this.logout_display = logout_display;
      if (this.logout_display == undefined) {
          this.logout_display = this.data.getSessionValue('logout_display');
      }
    });

    this.data.currentMode.subscribe((value) => {
      if (this.data.isRC() != undefined && this.data.isRC() == true) {
        this.patient_mode = false;
      }
      else {
        this.patient_mode = true;
      }
    });

      this.data.currentModuleElement.subscribe((value) => {
        this.moduleElement = value;
      });

      this.data.currentLanguages.subscribe((value) => {
        if (!value || value.length == 0) {
          this.study_languages = this.data.getSessionValue('supported_languages');
        }
        else {
          this.study_languages = value;
        }
      });
      this.all_languages = this.data.getSessionValue("all_languages");
      this.data.currentLanguageSelected.subscribe((value)=>{

        if(value){
        this.langSelect=value;
        this.translate.use(this.langSelect.toString());
        this.translate.getTranslation(value.toString()).subscribe(values => {});
        }
      });
  }


  changepage(ob) {

    var thisuser = this.data.getSessionValue('user');

    this.data.setCurrentLanguage(ob.value);
    this.translate.use(ob.value);
    if (this.patient_mode) {
      this.data.setModuleElement(undefined);
    }
    else {
      this.eventService.add_event("CHANGE_LANGUAGE","Change Language to " + ob.value , "SELECTION SCREENS",thisuser.Username);
    }


  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogLogoutConfirmComponent, {
      width: '250px',
      data: {logout: this.big_logout}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      this.big_logout = result;

      if (this.logout_display && this.big_logout) {
        this.cookieService.logoutCookies()
        .subscribe((cookiemsg) => {
          this.cookiemsg = cookiemsg;
          this.router.navigate([`/logout`]);
        }, errmess => this.errMess = <any>errmess);
      }

    });
  }

  signout() {

    this.openDialog();

  }

}


