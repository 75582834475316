import { Component, OnInit } from '@angular/core';
import { StudyService } from '../../services/study.service';
import { Study } from '../../shared/study';
import { ProqDataService } from '../../services/data.service';
import { ProqMode } from '../../shared/mode';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-study',
  templateUrl: './select-study.component.html',
  styleUrls: ['./select-study.component.scss']
})
export class SelectStudyComponent implements OnInit {

  studies: Study[];
  errMess: String;
  myuser: String;
  thisstudy: Study;
  thismode: ProqMode = new ProqMode;
  patientmode: Boolean;
  numberofstudies: number;
  sys_messages: String[];

  constructor(private studyService: StudyService,
              private data: ProqDataService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslateService) {

                translate.stream(['PROQ.GLOBAL.A','STANDARD_LIBRARY.TEXT_STUDY']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['STANDARD_LIBRARY.TEXT_STUDY']);

                });
              }

  ngOnInit() {
    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    
    this.numberofstudies = 0;
    this.studies = this.data.getSessionValue('studies');
    this.numberofstudies = this.studies.length;
    if (this.studies.length == 1) {
      this.newStudy(this.studies[0]);
      this.router.navigate([`/select/site`], { relativeTo: this.route });
    }
      
    this.data.currentStudy.subscribe(selectedstudy => this.thisstudy = selectedstudy);

    this.thismode.PatientMode = false;
    this.data.setMode(this.thismode); // Sets mode initially to RC
    
  }

  newStudy(pStudy: Study) {

    this.data.setStudy(pStudy);

    let thislang = this.translate.currentLang;
    
    let all_languages = this.data.getSessionValue('all_languages'); // Get all languages available
    let study_languages: string[] = [];

    all_languages.forEach((lang) => {
      this.translate.getTranslation(lang).subscribe(values => {
        if (values['STUDIES'][this.thisstudy.StudyCode]['SUPPORTED_LANGUAGES'] == 'Y') {
              study_languages.push(lang);
              this.data.setSupportedLanguages(study_languages);
          }
        })
      });

    // Set it back to prior language
    if (thislang == undefined) {thislang = 'en';}
    this.translate.getTranslation(thislang).subscribe(values => {});

  }

}
