import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { ProqDataService } from './data.service';
import { CheckstudyaccessService } from './checkstudyaccess.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { ProqMode } from '../shared/mode';
import { SessionStorageService, SessionStorage, LocalStorage, LocalStorageService } from 'angular-web-storage';
import {EventService} from './event.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private baseUrl: string = 'proq';

  constructor(private dataService: DataService,
              private proqDataService: ProqDataService,
              private processHTTPMsgService: ProcessHTTPMsgService,
              public session: SessionStorageService,
              public local: LocalStorageService,
              private checkstudyaccessService: CheckstudyaccessService,
              private eventService: EventService) { }


  logoutCookies() : Observable<any[]> {
    var thismode = new ProqMode;
    thismode.PatientMode = false;
    this.proqDataService.setMode(thismode);

    this.proqDataService.clearAccessTime();
    //on safari after logout since we are not loading all the langugaes again and its only done as part of app init. we need to keep track of the languages
    var all_languages = this.proqDataService.getSessionValue("all_languages");
    var current_language = this.proqDataService.getSessionValue("currentLanguage");
    var thisuser = this.proqDataService.getSessionValue("user");
    if (thisuser) {
      this.eventService.add_event("LOGOUT", "Logging out", "LOGOUT", thisuser.Username);
    }
    this.session.clear();
    this.proqDataService.setSessionValue("all_languages",all_languages);
    this.proqDataService.setSessionValue("currentLanguage",current_language);

    this.local.clear();
    return this.dataService.getList(this.baseUrl + '/logoutCookies')
        .pipe(catchError(this.processHTTPMsgService.handleError));

  }


  //authCookies
  createAuthCookies(pUser: string, pPassword: string): Observable<any> {
    var thismode = new ProqMode;
    thismode.PatientMode = false;
    this.proqDataService.setMode(thismode);

    this.proqDataService.clearAccessTime();

    if (window.location.hostname == 'localhost') {
      return this.dataService.postRequest(this.baseUrl + '/authCookies', [pUser, pPassword])
        .pipe(catchError(this.processHTTPMsgService.handleError));

    }
    else {
      return this.dataService.postAuthRequest('', [pUser, pPassword])
      .pipe(catchError(this.processHTTPMsgService.handleError));
    }

  }

  removeHorizonAccess(): Observable<any[]> {
    return this.dataService.getList(this.baseUrl + '/removeHorizonAccess')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}

