
<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"> -->
<div class="langselectdiv">
    <mat-form-field appearance="fill" class="langfield">
      <mat-icon  [ngStyle]="{'color':'white'}">language</mat-icon>
      <mat-select class="language-dropdown" [(ngModel)]="langSelect" (selectionChange)="changepage($event)" >
        <mat-option *ngFor="let lang of all_languages"  [value]="lang">
          {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + lang | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
<h1 class="proq-h1">PRO-Q</h1>
<mat-card class="login-mat-card">
  <form [formGroup]="loginForm" autocomplete="off" novalidate class="login-form">
    <mat-card-content class="patient-confirm-dialog">
        <div class="content">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="login-username">
                <input matInput formControlName="username" placeholder="{{ 'STANDARD_LIBRARY.TEXT_ENTER_USERNAME' | translate}}" value="" (keyup)="onKeyUsername($event)">
                <mat-error *ngIf="hasError('username', 'required')">{{'PROQ.ERROR.USERNAME_REQUIRED' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="login-password">
                <input matInput type="password" formControlName="password" placeholder="{{'STANDARD_LIBRARY.TEXT_ENTER_PASSWORD' | translate}}" (keyup)="onKeyPassword($event)">
                <mat-error *ngIf="hasError('password', 'required')">{{'PROQ.ERROR.PASSWORD_REQUIRED' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="mat-raised-button mat-primary login-button btn" (click)="login()" type="submit" [disabled]="!loginForm.valid || loading">
                <mat-progress-spinner mode="indeterminate" [diameter]="25" *ngIf="loading"></mat-progress-spinner>
                <span class="fas fa-sign-in-alt" *ngIf="!loading"></span>&nbsp; {{'STANDARD_LIBRARY.BUTTON.SIGN_IN' | translate}}
                </button>
            </div>
          </div>
        </div>
        <mat-error class="invalid-creds-error" *ngIf="errMess != null"><div  [innerHTML]="errMess"></div></mat-error>
    </mat-card-content>
  </form>
</mat-card>
<div *ngIf="errMess != null">
  <div [innerHTML]="supportMess"></div>
</div>
<cookie-law>
<div [innerHTML]="'PROQ.LOGIN.COOKIES' | translate"></div>
</cookie-law>