import { Component, Input, OnInit }  from '@angular/core';
import { FormGroup }                 from '@angular/forms';

import { QuestionBase }              from '../shared/question-model/question-base';
import { QuestionControlService }    from '../services/question-control.service';
import { SubmissionService } from '../services/submission.service';
import { ProqDataService } from '../services/data.service';
import { MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { PatientConfirmationComponent } from '../main-patient/patient-confirmation/patient-confirmation.component';
import { Router, ActivatedRoute } from '@angular/router';

import { Patient } from '../shared/patient';
import { Visit } from '../shared/visit';
import { Form } from '../shared/form';
import { Site } from '../shared/site';
import { Study } from '../shared/study';
import { User } from '../shared/user';
import { Bullseye, SlideInOutAnimation} from '../shared/animations';
import { TimeoutGuardService as TimeoutGuard } from '../guards/timeout-guard.service';
import { ProqService} from '../services/proq.service';

import {DcapiDetail} from '../shared/submission-model/dcapi-detail';
import {EventService} from '../services/event.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [ QuestionControlService ],
  animations: [SlideInOutAnimation, Bullseye]
})
export class DynamicFormComponent implements OnInit {

  current_page: number = 1;
  submission_id: number = 0; // this submission id for a given form

  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  thisvisit: Visit;
  selectedforms: Form[];
  thisform: Form;
  thisuser: User;
  result: Boolean;
  today: Date;

  loading: Boolean = false;

  animationstate = 1;

  show_bullseye = false;

  @Input() questions: QuestionBase<any>[] = [];
  form: FormGroup;
  errMess: string;

  constructor(private qcs: QuestionControlService,
              private submissionService: SubmissionService,
              private data: ProqDataService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private timeoutguard: TimeoutGuard,
              private eventService: EventService,
              private proqService: ProqService) {  }

  ngOnInit() {

    this.today = new Date();
    this.data.setSessionValue("submission_id",0);

    this.data.currentStudy.subscribe(selectedstudy => {
      this.thisstudy = selectedstudy;
      if (this.thisstudy == undefined) {
        this.thisstudy = this.data.getSessionValue('study');
      }
    });

    this.data.currentSite.subscribe(selectedsite => {
      this.thissite = selectedsite;
      if (this.thissite == undefined) {
        this.thissite = this.data.getSessionValue('site');
      }
    });

    this.data.currentPatient.subscribe(selectedpatient => {
      this.thispatient = selectedpatient;
      if (this.thispatient == undefined) {
        this.thispatient = this.data.getSessionValue('patient');
      }
    });

    this.data.currentVisit.subscribe(selectedvisit => {
      this.thisvisit = selectedvisit;
      if (this.thisvisit == undefined) {
        this.thisvisit = this.data.getSessionValue('visit');
      }
    });


    this.data.currentForms.subscribe(selectedforms => {
      this.selectedforms = selectedforms;
      if (this.selectedforms == undefined) {
        this.selectedforms = this.data.getSessionValue('forms');
      }
    });

    this.data.currentForm.subscribe(currentForm => {
      this.thisform = currentForm;
      if (this.thisform == undefined) {
        this.thisform = this.data.getSessionValue('form');
      }
    });

    this.proqService.getUser()
    .subscribe((userdetails) => {
      this.thisuser = userdetails;
      this.data.setUser(this.thisuser);
      },
      errmess => this.errMess = <any>errmess);

    this.form = this.qcs.toFormGroup(this.questions);

  }



  onSubmit() {
    this.thisstudy = this.data.getSessionValue('study');
    this.thissite = this.data.getSessionValue('site');
    this.thispatient = this.data.getSessionValue('patient');
    this.thisvisit = this.data.getSessionValue('visit');
    this.selectedforms = this.data.getSessionValue('forms');
    this.thisform = this.data.getSessionValue('form');
    this.thisuser = this.data.getSessionValue('user');
    this.submission_id = this.data.getSessionValue('submission_id');

    return this.submissionService.submit(this.form, this.thisstudy, this.thissite, this.thispatient, this.thisvisit, this.thisform, this.thisuser, this.submission_id);

  }


  onSubmissionStart() {
    this.thisstudy = this.data.getSessionValue('study');
    this.thissite = this.data.getSessionValue('site');
    this.thispatient = this.data.getSessionValue('patient');
    this.thisvisit = this.data.getSessionValue('visit');
    this.selectedforms = this.data.getSessionValue('forms');
    this.thisform = this.data.getSessionValue('form');
    this.thisuser = this.data.getSessionValue('user');

    return this.submissionService.start_submission(this.form, this.thisstudy, this.thissite, this.thispatient, this.thisvisit, this.thisform, this.thisuser);

  }

  setSubmissionDetail() {

    this.thisstudy = this.data.getSessionValue('study');
    this.thissite = this.data.getSessionValue('site');
    this.thispatient = this.data.getSessionValue('patient');
    this.thisvisit = this.data.getSessionValue('visit');
    this.selectedforms = this.data.getSessionValue('forms');
    this.thisform = this.data.getSessionValue('form');
    this.thisuser = this.data.getSessionValue('user');
    this.submission_id = this.data.getSessionValue('submission_id');

    var thisquestion = this.data.getSessionValue("current_question");

    return this.submissionService.set_detail(this.form, this.thisstudy, this.thissite, this.thispatient, this.thisvisit, this.thisform, this.thisuser, this.submission_id, thisquestion);

  }


  show_question(question: QuestionBase<any>) {
    if (question.order == this.current_page-1) {
      this.animationstate = this.animationstate === 1 ? 2 : 1;
      this.data.setSessionValue("current_question",question);
      return true;    }
    else {
      return false;
    }
  }

  hit_bullseye() {
    this.show_bullseye = true;
  }

  bye_bullseye() {
     this.show_bullseye = false;
  }

  continue() {
    this.loading = true;
    this.timeoutguard.checkTimeout();
    var question_group_valid = true;

    this.submission_id = this.data.getSessionValue("submission_id");

    if (this.current_page == 1) {
      this.current_page++;
      if (this.submission_id == 0) {
        this.onSubmissionStart().then(
            (x) => {
              this.thisform = this.data.getSessionValue('form');
              this.thisstudy = this.data.getSessionValue('study');
              this.thissite = this.data.getSessionValue('site');
              this.thispatient = this.data.getSessionValue('patient');
              this.thisvisit = this.data.getSessionValue('visit');
              this.thisuser = this.data.getSessionValue('user');

              if (x.SUBMISSION_ID) {
                this.data.setSessionValue("submission_id", x.SUBMISSION_ID);
                this.eventService.add_event("SUBMISSION SET","Submission ID: " + x.SUBMISSION_ID.toString() + " " + this.thisstudy.StudyCode + " " +
                                                                                                this.thissite.Site + " " + this.thisvisit.VisitName + " " + this.thispatient.Patient + " "
                                                                                                + this.thisform.FormName, "ENTER PATIENT SCREEN",this.thisuser.Username);
                if (x.DETAILS != undefined) {
                  for (var i = 0; i < x.DETAILS.length; i++) {
                    if (x.DETAILS[i].VALUE_TEXT != "") {
                      for (var a = 0; a < this.thisform.ProqQuestions.length; a++) {
                        if (this.thisform.ProqQuestions[a].OcDcmQuestionId == x.DETAILS[i].DCM_QUESTION_ID) {
                          if (this.form.get(this.thisform.ProqQuestions[a].ProqFormQuestionId.toString())) {
                            this.form.get(this.thisform.ProqQuestions[a].ProqFormQuestionId.toString()).setValue(x.DETAILS[i].VALUE_TEXT);
                          }

                          a = this.thisform.ProqQuestions.length;
                        }
                      }
                    }
                  }
                }

                var vdcapiDetails: DcapiDetail[] = this.submissionService.setHiddenDcapiDetails(this.form, this.thisform, this.thisuser, x.SUBMISSION_ID);
              }
              else {
                alert("Network error. Please press Next again. If error persists, notify Clinical Personnel.");
              }
            },
            error => {
              console.log("ERROR: " + JSON.stringify(error.message));
            });

        this.loading = false;
      }
      else {
        this.loading = false;
      }
    }
    else {
      if (this.submission_id == 0) {
        return;
      }
      for (var i=(this.current_page-2); i < this.questions.length && i < this.current_page-1; i++) {
        this.form.get(this.questions[i].key.toString()).markAsTouched();

        if (this.form.get(this.questions[i].key.toString()).hasError('required')) {
          question_group_valid = false;
          this.loading = false;
        }
      }

      if (question_group_valid == true) {
        this.setSubmissionDetail()
          .then(
            (x) => {
              this.loading = false;

              if (x.DetailStatus == "PENDING") { // Network error detected
                alert("Network Error. Please press Next again. If error persists, notify Clinical Personnel");
              }
              else {

                if (question_group_valid && ((this.current_page - 1) < this.questions.length)) {

                  this.current_page++;
                } else if (question_group_valid && ((this.current_page - 1) == this.questions.length)) { // Time to submit
                  this.openDialog();
                }
              }
              this.bye_bullseye();

            });
      }
    }


  }

  back() {
    this.timeoutguard.checkTimeout();

    this.current_page--;
  }


  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;

    const dialogRef = this.dialog.open(PatientConfirmationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        dialogResult => {this.result = dialogResult;
                if (this.result == true) {
                  this.onSubmit().then(
                      (x) => {

                        if (x.SubmissionStatus == "PENDING") { // network error
                          this.loading = false;
                          this.eventService.add_event("SAVE ERROR ","Submission ID: " + this.submission_id.toString(), "ENTER PATIENT SCREEN", this.thisuser.Username);
                          alert ("Network Error. Please try saving again. If error persists, notify clinical personnel");
                        }
                        else {
                          this.loading = false;
                          this.thisstudy = this.data.getSessionValue('study');
                          this.thissite = this.data.getSessionValue('site');
                          this.thispatient = this.data.getSessionValue('patient');
                          this.thisvisit = this.data.getSessionValue('visit');
                          this.thisform = this.data.getSessionValue('form');
                          this.thisuser = this.data.getSessionValue('user');

                          this.eventService.add_event("SUBMITTED ", "Submission ID: " + x.SUBMISSION_ID.toString() + " " + this.thisstudy.StudyCode + " " + this.thissite.Site + " " +
                            this.thisvisit.VisitName + " " + this.thispatient.Patient + " " + this.thisform.FormName, "ENTER PATIENT SCREEN", this.thisuser.Username);

                          this.data.setSessionValue("submit_error", false);

                          // Remove current form from list of forms
                          this.data.removeForm(this.thisform);
                          this.selectedforms = this.data.getSessionValue("forms");

                          if (this.selectedforms.length > 0) {
                            // This is a hack to force Angular to refresh the current link and reload the next form
                            this.router.navigateByUrl('/patient/enter2', {skipLocationChange: true}).then(() => this.router.navigate([`/patient/enter`]));
                          } else {
                            this.router.navigate([`/patient/farewell`]);
                          }
                        }
                      },
                      error => {
                        this.data.setSessionValue("submit_error",true);

                        this.submission_id = this.data.getSessionValue('submission_id');
                        this.thisuser = this.data.getSessionValue('user');

                        this.eventService.add_event("SAVE ERROR ","Submission ID: " + this.submission_id.toString() + " " + error.message, "ENTER PATIENT SCREEN", this.thisuser.Username);

                        if (this.selectedforms.length > 0) {
                          // This is a hack to force Angular to refresh the current link and reload the next form
                          this.router.navigateByUrl('/patient/enter2', {skipLocationChange: true}).then(() => this.router.navigate([`/patient/enter`]));
                        } else {
                          this.router.navigate([`/patient/farewell`]);
                        }

                      }
                  );
                }
            }
    );

  }







}
