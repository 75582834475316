<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"> -->
<div class="langselectdiv" *ngIf="study_languages.length > 1">
    <mat-form-field appearance="fill">
        <mat-icon  [ngStyle]="{'color':'white'}">language</mat-icon>
        <mat-select class="language-dropdown" [(ngModel)]="langSelect" (selectionChange)="changepage($event)" >
            <mat-option *ngFor="let lang of study_languages" [value]="lang">
                {{'STANDARD_LIBRARY.ISO_LANGUAGE_CODE.' + lang | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="waiting == false">
  <h1 mat-dialog-title>
    {{'PROQ.PT_WELCOME.TITLE_WELCOME' | translate}}
  </h1>
  <mat-dialog-content class="patient-confirm-dialog">
    <div [innerHTML]="('PROQ.PT_WELCOME.DEAR_PARTICIPANT') | translate"></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="container">
      <div class="row">
        <div class="col-xs-12"><button class="mat-raised-button cancel-button" (click)="wait()">{{'PROQ.PT_CONFIRM.WAIT_I_HAVE_QUESTIONS' |  translate}}</button></div>
        <div class="col-xs-12"><button class="mat-raised-button mat-primary confirm-button"(click)="confirm()">{{'PROQ.PT_CONFIRM.NO_QUESTIONS_CONTINUE' | translate}}</button></div>
      </div>
    </div>
  </mat-dialog-actions>
</div>
<div *ngIf="waiting == true && five_min_warning == false">
  <h1 mat-dialog-title>
    {{'PROQ.PT_WELCOME.TITLE_WAITING' | translate}}
  </h1>
  <mat-dialog-content class="patient-confirm-dialog">
    <div innerHtml="'PROQ.PT_WELCOME.PLEASE_TAKE_A_MOMENT' | translate"></div>
    <br><br>
    {{'PROQ.PT_CONFIRM.SYSTEM_WILL_TIMEOUT' | translate}}
    <br><br>
    <mat-progress-bar
          class="timeleft-progress"
          [color]="color"
          [mode]="determinate"
          [value]="time_left_pct">
      </mat-progress-bar>

    {{'PROQ.PT_CONFIRM.REMAINING_TIME' | translate}}: {{time_left_rounded}} {{'PROQ.PT_CONFIRM.MINUTES' | translate}}
    
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="container">
      <div class="row">
        <div class="col-xs-12"><button class="mat-raised-button cancel-button" (click)="logout()">{{'PROQ.PT_CONFIRM.BUTTON_NOT_CONTINUE' | translate}}</button></div>
        <div class="col-xs-12"><button class="mat-raised-button mat-primary confirm-button"(click)="confirm()">{{'PROQ.PT_CONFIRM.BUTTON_READY_TO_CONTINUE' | translate}}</button></div>
      </div>
    </div>
  </mat-dialog-actions>
</div>
<div *ngIf="five_min_warning == true">
    <h1 mat-dialog-title>
      {{'PROQ.PT_CONFIRM.TITLE_FIVE_MINUTE_WARNING' | translate}}
      </h1>
      <mat-dialog-content class="patient-confirm-dialog">
        {{'PROQ.PT_CONFIRM.LESS_THAN_FIVE_MINUTES' | translate}}
        <br><br>
        <mat-progress-bar
              class="timeleft-progress"
              [color]="color"
              [mode]="determinate"
              [value]="time_left_pct">
          </mat-progress-bar>
         
        {{'PROQ.PT_CONFIRM.REMAINING_TIME' | translate}}: {{time_left_rounded}} {{'PROQ.PT_CONFIRM.MINUTES' | translate}}
        
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="container">
          <div class="row">
            <div class="col-xs-12"><button class="mat-raised-button cancel-button" (click)="logout()">{{'PROQ.PT_CONFIRM.BUTTON_NOT_CONTINUE' | translate}}</button></div>
            <div class="col-xs-12"><button class="mat-raised-button mat-primary confirm-button"(click)="reset_timeout()">{{'PROQ.PT_CONFIRM.BUTTON_READY_TO_CONTINUE' | translate}}</button></div>
          </div>
        </div>
      </mat-dialog-actions>
</div>
