import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export class Settings {
  authority: string;
  resource: string;
  authResource: string;
  clientId: string;
  appKey: string;
}

@Injectable()
export class Configuration {

  public settings: Settings;
  public isLoaded = false;

  constructor() {
    if (this.settings) { return; }

    this.loadSettings();
  }

  loadSettings(): void {

    const settings = new Settings();

    settings.authority = environment.authority;
    settings.resource = environment.resource;
    settings.authResource = environment.authResource;
    settings.clientId = environment.clientId;
    settings.appKey = environment.appKey;

    this.settings = settings;
    this.isLoaded = true;
  }
}
