import { Component, OnInit, OnDestroy } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { Study } from '../../shared/study';
import { Site  } from '../../shared/site';
import { ProqDataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-site',
  templateUrl: './select-site.component.html',
  styleUrls: ['./select-site.component.scss']
})

export class SelectSiteComponent implements OnInit {

  thisstudy: Study;
  thissite: Site;
  sites: Site[];
  errMess: String;
  studyid: number;
  numbersites: number;
  MySiteSelect: FormControl = new FormControl();
  filteredOptions: String[];
  filteredSites: Site[];
  sitePanelOptions: String[];
  sys_messages: String[];

  constructor(private data: ProqDataService, 
              private siteService: SiteService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslateService) {

                translate.stream(['PROQ.GLOBAL.A','STANDARD_LIBRARY.TEXT_SITE']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['STANDARD_LIBRARY.TEXT_SITE']);

                });
              }
              
  ngOnInit() {
    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    

    this.numbersites = 0;
    this.data.currentStudy.subscribe(selectedstudy => {
        this.thisstudy = selectedstudy; 
        if (this.thisstudy.StudyId == undefined) { 
          this.thisstudy = this.data.getSessionValue('study');
        }
    });

    this.data.currentSite.subscribe(selectedsite => this.thissite = selectedsite);
   // this.data.setModuleElement('a SITE');

    this.studyid = this.thisstudy.StudyId;
  
    // Protection statements in case something went wrong with the session storage
    if (this.studyid == null) { this.router.navigate([`/select/study`]);}
    
    this.siteService.getSites(this.studyid)
      .subscribe((sites) => {
          this.sites = sites;
          this.data.setSites(this.sites);
          this.numbersites = this.sites.length;
          if (this.sites.length == 1) {
            this.newSite(this.sites[0]);
            this.router.navigate([`/select/patient`], { relativeTo: this.route });

          }

          this.filteredSites = this.sites;
  
          this.sitePanelOptions = [];
          for (var i=0; i< this.sites.length; i++) {
            this.sitePanelOptions.push(this.sites[i].SiteName + " (" + this.sites[i].Site + ")");
          }
          this.filteredOptions = this.sitePanelOptions;
        },
      errmess => this.errMess = <any>errmess);
    
    this.MySiteSelect.valueChanges
        .subscribe(data => {
          if (data != null && data != undefined && (typeof data == "string")) {
            this.filteredSites = this.filterBy(data);
            this.filteredOptions = this._filter(data);
          }
        });
      }
    
    private _filter(value: string): String[] {
      const filterValue = value.toLowerCase();
    
      return this.sitePanelOptions.filter(option => option.toLowerCase().includes(filterValue));
    }
  
    filterBy(prop: string) {
      return this.sites.filter(item => item.SiteName.toLowerCase().indexOf(prop.toLowerCase()) + item.Site.toLowerCase().indexOf(prop.toLowerCase()) > -2);
    }
  


  newSite(pSite: Site) {
    this.data.setSite(pSite);
    this.MySiteSelect.valueChanges
  }

}

