export class DcapiDetail {
    SUBMISSION_DETAIL_ID: number;
    SUBMISSION_ID: number;
    QUESTION_GROUP_NAME: string;
    QUESTION_NAME: string;
    DCM_QUESTION_ID: number;
    OCCURRENCE_SN: number;
    REPEAT_SN: number;
    VALUE_TEXT: string;
    RESPONSE_AUDIT_REASON: string;
    RESPONSE_AUDIT_COMMENT: string;
}

