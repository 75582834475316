import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../shared/user';

@Injectable({
  providedIn: 'root'
})
export class ProqService {

  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService,
              private http: HttpClient) { }


  getUser() : Observable<User> {
    return this.dataService.getList(this.baseUrl + '/user')
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}
