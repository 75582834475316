import { Component, OnInit } from '@angular/core';
import { ProqDataService } from '../../services/data.service';
import { Visit } from '../../shared/visit';
import { Study } from '../../shared/study';
import { Site } from '../../shared/site';
import { Patient } from '../../shared/patient';
import { Form } from '../../shared/form';
import { FormService } from '../../services/form.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { RcConfirmationComponent } from '../rc-confirmation/rc-confirmation.component';
import { SlideInOutAnimation, Bullseye } from '../../shared/animations';
import { TimeoutGuardService as TimeoutGuard } from '../../guards/timeout-guard.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-form',
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss'],
  animations: [SlideInOutAnimation, Bullseye]
})
export class SelectFormComponent implements OnInit {
  visits: Visit[];
  forms: Form[] = []; // Keeps all forms

  errMess: String;
  studyid: number;
  siteid: number;
  patientid: number;
  visitid: number;

  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  thisvisit: Visit;
  thisform: Form;
  selectedforms: Form[] = [];
  formCount: number = 0;
  show_bullseye = false;
  sys_messages: String[];

  constructor(private data: ProqDataService, 
              private formService: FormService,
              private dialog: MatDialog, 
              private timeoutGuard: TimeoutGuard,
              private router: Router,
              public translate: TranslateService) {

                translate.stream(['PROQ.GLOBAL.A','PROQ.GLOBAL.ONE_OR_MORE','PROQ.RC_GENERIC.QUESTIONNAIRES',
                                                  'PROQ.GLOBAL.A','PROQ.RC_GENERIC.QUESTIONNAIRE']).subscribe((result: string[]) => {
                  this.sys_messages = result;
                  if (this.forms && this.forms.length > 1) {
                    this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.ONE_OR_MORE'] + ' ' + this.sys_messages['PROQ.RC_GENERIC.QUESTIONNAIRES']);

                 //   this.data.setModuleElement('one or more QUESTIONNAIRES');
                  }
                  else {
                    this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['PROQ.RC_GENERIC.QUESTIONNAIRE']);
           
                  //  this.data.setModuleElement('a QUESTIONNAIRE');
                  }
                });
              }

  ngOnInit() {
    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    

    this.data.currentStudy.subscribe(selectedstudy => {
      this.thisstudy = selectedstudy;
      if (this.thisstudy == undefined) { 
        this.thisstudy = this.data.getSessionValue('study');
      }
    });

    this.data.currentSite.subscribe(selectedsite => {
      this.thissite = selectedsite;
      if (this.thissite == undefined) { 
        this.thissite = this.data.getSessionValue('site');
      }
    });

    this.data.currentPatient.subscribe(selectedpatient => {
      this.thispatient = selectedpatient;
      if (this.thispatient == undefined) { 
        this.thispatient = this.data.getSessionValue('patient');
      }
    });

    this.data.currentVisit.subscribe(selectedvisit => {
      this.thisvisit = selectedvisit;
      if (this.thisvisit == undefined) { 
        this.thisvisit = this.data.getSessionValue('visit');
      }
    });


    this.data.currentForms.subscribe(selectedforms => {
      this.selectedforms = selectedforms;
      if (this.selectedforms == undefined) { 
        this.selectedforms = this.data.getSessionValue('forms');
      }
    });

    // Protection statements in case something went wrong with the session storage
    if (this.thisstudy == null || this.thisstudy.StudyId == null) { this.router.navigate([`/select/study`]);}
    else if (this.thissite == null || this.thissite.SiteId == null) {this.router.navigate([`/select/site`]);} 
    else if (this.thispatient == null || this.thispatient.PatientId == null) {this.router.navigate([`/select/patient`]);}
    else if (this.thisvisit == null || this.thisvisit.VisitId == null) {this.router.navigate([`/select/visit`]);}
    
    this.studyid = this.thisstudy.StudyId;
    this.siteid = this.thissite.SiteId;
    this.patientid = this.thispatient.PatientId;
    this.visitid = this.thisvisit.VisitId;


    this.formService.getForms(this.studyid,this.siteid,this.patientid, this.visitid)
    .subscribe((forms) => {
      this.forms = forms;
      if (this.forms.length > 1) {
        this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.ONE_OR_MORE'] + ' ' + this.sys_messages['PROQ.RC_GENERIC.QUESTIONNAIRES']);
      }
      else {
        this.data.setModuleElement(this.sys_messages['PROQ.GLOBAL.A'] + ' ' + this.sys_messages['PROQ.RC_GENERIC.QUESTIONNAIRE']);
      }
    },
      errmess => this.errMess = <any>errmess);
  }

  

  NewForm(thisForm: Form) {
    this.timeoutGuard.checkTimeout();

    this.data.setForm(thisForm);
    this.formCount++;

    if (this.formCount >= this.forms.length) {
      this.openDialog();
    }
    else {
      this.hit_bullseye_now();
    }
  }

  RemoveForm(thisForm: Form) {
    this.timeoutGuard.checkTimeout();

    thisForm.FormSelected = false;
    this.data.removeForm(thisForm);
    this.formCount--;
    this.hit_bullseye();
  }

  FilterForms(form: Form) {
    if (form == undefined) { return false;}

    if (form.FormSelected == true) {
      return false;
    }
    else {
      return true;
    }
  }

  FilterSelectedForms(form: Form) {
    if (form == undefined) { return false;}

    if (form.FormSelected == true) {
      return true;
    }
    else {
      return false;
    }
  }

  hit_bullseye() {
    this.show_bullseye = false;
    setTimeout(() => {this.show_bullseye = true},250);
  }

  hit_bullseye_now() {
    this.show_bullseye = true;
  }


  bye_bullseye() {
    this.show_bullseye = false;
  }
  
  openDialog() {
    this.timeoutGuard.checkTimeout();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;

    const dialogRef = this.dialog.open(RcConfirmationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {}
    ); 

  }


}
