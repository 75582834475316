<div class="container-fluid" style="margin-left: -30px; margin-right: 30px; height:100%">
    <div class="row no-gutters align-items-stretch" >
      <div class="col-12 col-sm-6 col-md-8 d-flex align-items-stretch">
        <div class="card holder-lft">
          <div class="col-xs-12 col-sm-6" *ngFor="let thisvisit of visits">
              <mat-card class="visit" [class.mat-card-highlighted]="thisvisit.visitStatus == 'Expected'" [class.mat-elevation-z20]="thisvisit.VisitStatus == 'Expected'" (click)="NewVisit(thisvisit);" routerLink="/select/form" routerLinkActive="active">
                <mat-card-title><span class="fa fa-calendar"></span> {{thisvisit.VisitDescription}}</mat-card-title>
                <mat-card-subtitle>
                  <div class="row">
                    <div class="col-sm-12">{{'STANDARD_LIBRARY.TEXT_VISIT' | translate | uppercase}} {{thisvisit.VisitName}}</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12" *ngIf="!thisvisit.VisitLowerBoundDt">&nbsp;</div>
                    <div class="col-sm-12" *ngIf="thisvisit.VisitLowerBoundDt">
                      {{'STANDARD_LIBRARY.TEXT_EXPECTED' | translate | titlecase}} {{thisvisit.VisitLowerBoundDt | date:'dd-MMM-yyyy'}} {{'STANDARD_LIBRARY.TEXT_TO' | translate | lowercase}} {{thisvisit.VisitUpperBoundDt | date:'dd-MMM-yyyy'}}
                    </div>
                  </div>
                </mat-card-subtitle>
              </mat-card>
          </div>
   
        </div> <!-- END CARD HOLDER -->
    </div> <!-- COL -->

    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
      <div class="holder-rt">  
          <mat-card class="title-selected selections">{{'PROQ.RC_GENERIC.SELECTIONS' | translate | uppercase}}</mat-card>    
          <mat-card class="mat-card-selected study study-leave" [routerLink]="['/select/study']" routerLinkActive="active">
            <mat-card-title class="title-selected"><span class="fa fa-vial"></span> {{thisstudy.StudyTitle}}</mat-card-title>
            <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_STUDY'| translate | uppercase}} {{thisstudy.StudyCode}}</mat-card-subtitle>
          </mat-card>
          <mat-card class="mat-card-selected site site-leave study-leave" [routerLink]="['/select/site']" routerLinkActive="active">
            <mat-card-title class="title-selected"><span class="fa fa-hospital"></span> {{thissite.SiteName}}</mat-card-title>
            <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_SITE' | translate | uppercase}} {{thissite.Site}}</mat-card-subtitle>
          </mat-card>
          <mat-card class="mat-card-selected patient selected-patient patient-leave site-leave study-leave" [routerLink]="['/select/patient']" routerLinkActive="active">
            <mat-card-title class="title-selected"><span class="fa fa-user"></span> {{thispatient.SitePatientId}}</mat-card-title>   
            <mat-card-subtitle>
              <div class="row" *ngIf="thispatient.EnrollmentDate">
                <div class="col-xs-12">{{'STANDARD_LIBRARY.TEXT_ENROLLED' | translate | uppercase}} {{thispatient.EnrollmentDate | date:'dd-MMM-yyyy' }}</div>
              </div>
              <div class="row" *ngIf="!thispatient.EnrollmentDate">
                <div class="col-xs-12">{{'STANDARD_LIBRARY.TEXT_PATIENT' | translate | uppercase}} {{thispatient.Patient}}</div>
              </div>
            </mat-card-subtitle>   
          </mat-card>
      </div>
    </div> <!-- MAIN COL #2 -->     

  </div> <!-- BIG ROW -->
</div> <!-- FLUID CONTAINER -->

<div fxFlex *ngIf="errMess">
  <h2>Error</h2>
  <h4>{{errMess}}</h4>
</div>
