import { Injectable } from '@angular/core';
import { CoreService, DataService, SecurityService } from '../../template-shared/services/core';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { DatePipe } from '@angular/common';
import { FormGroup }                 from '@angular/forms';

import { Visit } from '../shared/visit';
import { Study } from '../shared/study';
import { Site } from '../shared/site';
import { Patient } from '../shared/patient';
import { Form } from '../shared/form';

import { DcapiMaster } from '../shared/submission-model/dcapi-master';
import { DcapiDetail } from '../shared/submission-model/dcapi-detail';
import { User } from '../shared/user';
import { catchError } from 'rxjs/operators';
import {ProqDataService} from './data.service';
import {QuestionBase} from '../shared/question-model/question-base';
import {getSortHeaderNotContainedWithinSortError} from '@angular/material/sort/sort-errors';
import {Router} from '@angular/router';
import {EventService} from './event.service';

@Injectable({
  providedIn: 'root'
})


export class SubmissionService {

  private baseUrl: string = 'proq_api';
  private working: boolean = false;

  private thisstudy: Study;
  private thissite: Site;
  private thispatient: Patient;
  private thisvisit: Visit;
  private thisform: Form;
  private selectedforms: Form[] = [];
  private thisUser: User;
  private errMess: string;

  constructor(private dataService: DataService,
              public datepipe: DatePipe,
              public data: ProqDataService,
              private router: Router,
              private eventService: EventService,
              private processHTTPMsgService: ProcessHTTPMsgService) {}

  ngOnInit() {
  }

  submit(pFormGroup: FormGroup, pStudy: Study, pSite: Site, pPatient: Patient, pVisit: Visit, pForm: Form, pUser: User, pSubmissionId: number) {
    var vdcapiMaster: DcapiMaster = this.setDcapiMaster(pStudy,pSite,pPatient,pVisit,pForm,pUser);
    var vdcapiDetails: DcapiDetail[] = this.setDcapiDetails(pFormGroup,pForm,pUser);
    vdcapiMaster.DETAILS = vdcapiDetails;
    vdcapiMaster.SUBMISSION_ID = pSubmissionId;

    var promise = this.dataService.postRequest(this.baseUrl + '/submit', vdcapiMaster).toPromise();
    return promise;
  }

  start_submission(pFormGroup: FormGroup, pStudy: Study, pSite: Site, pPatient: Patient, pVisit: Visit, pForm: Form, pUser: User) {
    var vdcapiMaster: DcapiMaster = this.setDcapiMaster(pStudy,pSite,pPatient,pVisit,pForm,pUser);

    var promise = this.dataService.postRequest(this.baseUrl + '/start_submission', vdcapiMaster).toPromise();
    return promise;

  }

  set_detail(pFormGroup: FormGroup, pStudy: Study, pSite: Site, pPatient: Patient, pVisit: Visit, pForm: Form, pUser: User, pSubmissionId: number, vQuestion: QuestionBase<any>) {

    var vDcapiDetail: DcapiDetail = this.setDcapiDetail(pFormGroup,pForm,pUser,pSubmissionId, vQuestion);

    var promise = this.dataService.postRequest(this.baseUrl + '/set_submission_detail', vDcapiDetail).toPromise();
    return promise;

  }


  setDcapiMaster(pStudy: Study,pSite: Site, pPatient: Patient, pVisit: Visit, pForm: Form, pUser: User): DcapiMaster {
    var vdcapiMaster: DcapiMaster = new DcapiMaster;

    vdcapiMaster.APP_NAME = 'PRO-Q';
    vdcapiMaster.APP_ID = 500;
    vdcapiMaster.CLINICAL_STUDY_ID = pStudy.StudyId;
    vdcapiMaster.DCI_DCM_DATE = this.datepipe.transform(new Date(),'yyyyMMdd');
    vdcapiMaster.DCI_SHORT_NAME = pForm.OcDciShortName;
    vdcapiMaster.DCM_SHORT_NAME = pForm.OcDcmShortName;
    vdcapiMaster.DCM_SUBSET_SN = pForm.OcDcmSubsetSn;
    vdcapiMaster.DCM_LAYOUT_SN = pForm.OcDcmLayoutSn;
    vdcapiMaster.SITE = pSite.Site;
    vdcapiMaster.SITE_ID = pSite.SiteId;
    vdcapiMaster.SITE_PT_ID = pPatient.SitePatientId;
    vdcapiMaster.STUDY = pStudy.StudyCode;
    vdcapiMaster.SUBEVENT_NUMBER = 0;
    vdcapiMaster.VISIT_NAME = pVisit.VisitName;
    vdcapiMaster.PATIENT = pPatient.Patient;
    vdcapiMaster.PATIENT_POSITION_ID  = pPatient.PatientId;
    vdcapiMaster.SUBMITTED_BY = pUser[0].Username;
    vdcapiMaster.STATUS = 'READY';
    vdcapiMaster.BROWSER = 'LANGUAGE: ' + this.data.getSessionValue("currentLanguage");
    vdcapiMaster.DE_MODE = 'P';
    vdcapiMaster.INVESTIGATOR = pSite.Investigator;
    vdcapiMaster.INVESTIGATOR_ID = pSite.InvestigatorId;
    vdcapiMaster.SET_OC_SITE_PT_ID = 'N';

    return vdcapiMaster;
  }

  setDcapiDetails(pFormGroup: FormGroup, pForm: Form, pUser: User): DcapiDetail[] {
    var vdcapiDetails: DcapiDetail[] = [];

    var vQuestions = pForm.ProqQuestions;

    for(var i=0; i<vQuestions.length; i++) {
      var vDetail = new DcapiDetail;
      if (vQuestions[i].OcQuestionType == 'STANDARD') {
        vDetail.VALUE_TEXT = pFormGroup.get(vQuestions[i].ProqFormQuestionId.toString()).value;
      }
      else if (vQuestions[i].OcQuestionHelpText != null && vQuestions[i].OcQuestionHelpText.includes('PROQ:USER')) {
        vDetail.VALUE_TEXT = pUser[0].Username;
      }
      else {
        vDetail.VALUE_TEXT = ''; // HIDDEN QUESTIONS ARE SET TO NULL
      }

      vDetail.DCM_QUESTION_ID = vQuestions[i].OcDcmQuestionId;
      vDetail.QUESTION_GROUP_NAME = vQuestions[i].OcQuestionGroupName;
      vDetail.QUESTION_NAME = vQuestions[i].OcQuestionName;
      vDetail.REPEAT_SN = 1;
      vDetail.OCCURRENCE_SN = vQuestions[i].OcQuestionOccurrenceSn;
      vdcapiDetails.push(vDetail);
    }

    return vdcapiDetails;

  }

  setHiddenDcapiDetails(pFormGroup: FormGroup, pForm: Form, pUser: User, pSubmissionId: number): DcapiDetail[] {
    var vdcapiDetails: DcapiDetail[] = [];

    var vQuestions = pForm.ProqQuestions;

    for(var i=0; i<vQuestions.length; i++) {
      var vDetail = new DcapiDetail;
      if (vQuestions[i].OcQuestionType == 'HIDDEN') {
        if (vQuestions[i].OcQuestionHelpText != null && vQuestions[i].OcQuestionHelpText.includes('PROQ:USER')) {
          vDetail.VALUE_TEXT = pUser[0].Username;
        } else {
          vDetail.VALUE_TEXT = ''; // HIDDEN QUESTIONS ARE SET TO NULL
        }

        vDetail.SUBMISSION_ID = pSubmissionId;
        vDetail.DCM_QUESTION_ID = vQuestions[i].OcDcmQuestionId;
        vDetail.QUESTION_GROUP_NAME = vQuestions[i].OcQuestionGroupName;
        vDetail.QUESTION_NAME = vQuestions[i].OcQuestionName;
        vDetail.REPEAT_SN = 1;
        vDetail.OCCURRENCE_SN = vQuestions[i].OcQuestionOccurrenceSn;

        this.dataService.postRequest(this.baseUrl + '/set_submission_detail', vDetail)
          .subscribe(
            (x) => {
              this.working = true;
            },
            error => {
              this.working = false;
              console.log("ERROR: " + JSON.stringify(error.message));
            },
            () => this.working = false
          );

      }
    }

    return vdcapiDetails;

  }

  setDcapiDetail(pFormGroup: FormGroup, pForm: Form, pUser: User, pSubmissionId: number, pQuestion: QuestionBase<any>): DcapiDetail {
    var vIdx = 0;
    this.thisstudy = this.data.getSessionValue('study').StudyCode;
    this.thisform = this.data.getSessionValue('form');

    var allQuestions = pForm.ProqQuestions;
    for (var x = 0; x < allQuestions.length; x++) {

      var compare_string = this.thisstudy + '.' + this.thisform.OcDcmShortName + '__' + this.thisform.OcDcmSubsetSn + '.QUESTIONS.' + allQuestions[x].OcQuestionName + '__' + allQuestions[x].OcQuestionOccurrenceSn + '.PROMPT';
      if (compare_string == pQuestion.label) {
        vIdx = x;
        break;
      }
    }

    var vQuestion = pForm.ProqQuestions[vIdx];

    var vDetail = new DcapiDetail;
    if (vQuestion.OcQuestionType == 'STANDARD') {
      vDetail.VALUE_TEXT = pFormGroup.get(vQuestion.ProqFormQuestionId.toString()).value;
    }
    else if (vQuestion.OcQuestionHelpText != null && vQuestion.OcQuestionHelpText.includes('PROQ:USER')) {
      vDetail.VALUE_TEXT = pUser[0].Username;
    }
    else {
      vDetail.VALUE_TEXT = ''; // HIDDEN QUESTIONS ARE SET TO NULL
    }
    vDetail.SUBMISSION_ID = pSubmissionId;
    vDetail.DCM_QUESTION_ID = vQuestion.OcDcmQuestionId;
    vDetail.QUESTION_GROUP_NAME = vQuestion.OcQuestionGroupName;
    vDetail.QUESTION_NAME = vQuestion.OcQuestionName;
    vDetail.REPEAT_SN = 1;
    vDetail.OCCURRENCE_SN = vQuestion.OcQuestionOccurrenceSn;

    return vDetail;

  }



}
