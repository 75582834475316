<div class="container-fluid" style="margin-left: -30px; margin-right: 30px; height:100%">
    <div class="row no-gutters align-items-stretch" >
      <div class="col-12 col-sm-6 col-md-8 d-flex align-items-stretch">
        <div class="card holder-lft">
          <div class="p-2" *ngIf="numberofstudies > 6">
            <mat-form-field>
              <input type="text" placeholder="{{'PROQ.RC_GENERIC.FILTER_STUDIES' | translate}}" matInput [matAutocomplete]="auto" >
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let st of studies" [value]="st.StudyCode">{{st.StudyTitle}} ({{st.StudyCode}})</mat-option>
            </mat-autocomplete>
          </div>
          <div class="col-xs-12 col-sm-6" *ngFor="let thisstudy of studies">
            <mat-card class="study" (click)="newStudy(thisstudy)" [routerLink]="['/select/site']" routerLinkActive="active">
            <mat-card-title class="md-toolbar-tools">
                <span class="fa fa-vial"></span> 
                {{thisstudy.StudyTitle}} 
                </mat-card-title>
                <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_STUDY' | translate | uppercase}} {{thisstudy.StudyCode}}</mat-card-subtitle>
              <mat-card-content fxFlexAlign="center">
                  {{thisstudy.LongTitle}}
              </mat-card-content>
            </mat-card>
          </div>
   
        </div> <!-- END CARD HOLDER -->
    </div> <!-- COL -->

    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
      <div class="holder-rt">    
       
      </div>
    </div> <!-- MAIN COL #2 -->     

  </div> <!-- BIG ROW -->
</div> <!-- FLUID CONTAINER -->

<div fxFlex *ngIf="errMess">
  <h2>Error</h2>
  <h4>{{errMess}}</h4>
</div>
