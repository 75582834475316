import { Component, OnInit} from '@angular/core';
import { Visit } from '../../shared/visit';
import { Study } from '../../shared/study';
import { Site } from '../../shared/site';
import { Patient } from '../../shared/patient';
import { Form } from '../../shared/form';
import { ProqDataService } from '../../services/data.service';
import {MatDialogRef} from "@angular/material/dialog";
import {ProqMode} from '../../shared/mode';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../services/event.service';

@Component({
  selector: 'app-rc-confirmation',
  templateUrl: './rc-confirmation.component.html',
  styleUrls: ['./rc-confirmation.component.scss']
})
export class RcConfirmationComponent implements OnInit {

  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  thisvisit: Visit;
  thismode: ProqMode;
  thisforms: Form[];
  thisformcount: number;
  sys_messages: String[];
  lang_err: String;
  preferred_lang: String = 'en';
  languages: String[];
  study_languages: string[] = [];
  langSelect:String='';
  constructor(private data: ProqDataService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslateService,
              private eventService: EventService,
              private dialogRef: MatDialogRef<RcConfirmationComponent>) {


               }

  ngOnInit() {

    this.data.currentStudy.subscribe(selectedstudy => this.thisstudy = selectedstudy);
    this.data.currentSite.subscribe(selectedsite => this.thissite = selectedsite);
    this.data.currentPatient.subscribe(selectedpatient => this.thispatient = selectedpatient);
    this.data.currentVisit.subscribe(selectedvisit => this.thisvisit = selectedvisit);
    this.data.currentMode.subscribe(selectedmode => this.thismode = selectedmode);
    this.data.currentForms.subscribe(selectedforms => this.thisforms = selectedforms);

    this.thisformcount = this.thisforms.length;

    // What are my current languages for this study
    this.data.currentLanguages.subscribe((value) => {
      if (!value || value.length == 0) {
        this.study_languages = this.data.getSessionValue('supported_languages');
      }
      else {
        this.study_languages = value;
      }

      let matched = false;
      let thislang = this.translate.currentLang;
      if (thislang == undefined) {
        thislang ='en';
      }

      this.study_languages.forEach(value => {
        if (thislang == value) {
          matched = true;
        }
      });

      if (!matched) {
        this.lang_err = this.translate.instant('STANDARD_LIBRARY.LANG_NOT_SUPPORTED');
      }
      else {
        this.lang_err = "";
      }
    });



  }

  changepage(ob) {
    this.translate.use(ob.value);
    this.data.setCurrentLanguage(ob.value);

    let matched = false;
    let thislang = this.translate.currentLang;

    var thisuser = this.data.getSessionValue('user');
    var thisformstring = "";
    var comma = "";
    for (var i=0;i<this.thisforms.length;i++) {
      thisformstring = thisformstring + comma + this.thisforms[i].FormName;
      comma = ", ";
    }
    this.study_languages.forEach(value => {
      if (thislang == value) {
        matched = true;
      }
    });

    if (!matched) {
      this.lang_err = this.translate.instant('STANDARD_LIBRARY.LANG_NOT_SUPPORTED');
      this.eventService.add_event("CHANGE_LANGUAGE_FAILED","Change Language to " + ob.value + "failed for " + this.thisstudy.StudyCode + " " + this.thissite.Site + " " +
        this.thisvisit.VisitName + " " + this.thispatient.Patient + " " + thisformstring, "RC CONFIRMATION SCREEN",thisuser.Username);
    }
    else {
      this.lang_err = "";
      this.eventService.add_event("CHANGE_LANGUAGE","Change Language to " + ob.value + " for " + this.thisstudy.StudyCode + " " + this.thissite.Site + " " +
        this.thisvisit.VisitName + " " + this.thispatient.Patient + " " + thisformstring, "RC CONFIRMATION SCREEN",thisuser.Username);


    }
  }


  close() {

    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close();
    this.thismode.PatientMode = true;
    this.data.setMode(this.thismode);
    this.goPatient();
  }


  goPatient() {
    var ptMode = new ProqMode;
    ptMode.PatientMode = true;
    this.data.setMode(ptMode);
    this.router.navigate([`/patient/enter`]);
  }

}
