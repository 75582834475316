<div class="row">
  <div class="col-sm-4"></div>
  <div class="col-sm-4">
    <mat-card class="content thankyou">
      <h1 mat-card-title>
        {{'STANDARD_LIBRARY.TEXT_THANK_YOU' | translate}}
      </h1>
      <mat-card-content class="thankyou-content" [routerLink]="['/login']" routerLinkActive="active">
        <div class="row">
          <div class="col-sm-12">
            <span style="font-weight: bold">
              {{'PROQ.PT_GOODBYE.TEXT_THANK_YOU' | translate}}<br><br>
              {{'PROQ.PT_GOODBYE.PLEASE_RETURN' | translate}}
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-4"></div>
</div>
