import { Component, OnInit }  from '@angular/core';

import {MatDialogRef} from "@angular/material/dialog";
import { ProqDataService } from '../../services/data.service';


@Component({
  selector: 'app-patient-confirmation',
  templateUrl: './patient-confirmation.component.html',
  styleUrls: ['./patient-confirmation.component.scss']
})
export class PatientConfirmationComponent implements OnInit {

  constructor(private data: ProqDataService,
              private dialogRef: MatDialogRef<PatientConfirmationComponent>) { }

  ngOnInit() {
    this.data.setSessionValue('module','patient');

  }
  

  
  close() {
    this.dialogRef.close(false);

  }

  confirm() {
    this.data.setSessionValue('patient-confirmed', true);

    this.dialogRef.close(true);
  }

  
}
