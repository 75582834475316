import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {MatProgressBarModule} from "@angular/material/progress-bar"
import { Router } from '@angular/router';
import { TimeoutGuardService as TimeoutGuard } from '../../guards/timeout-guard.service';
import {TranslateService} from '@ngx-translate/core';
import {ProqDataService} from '../../services/data.service';
import {EventService} from '../../services/event.service';

@Component({
  selector: 'app-patient-welcome',
  templateUrl: './patient-welcome.component.html',
  styleUrls: ['./patient-welcome.component.scss']
})
export class PatientWelcomeComponent implements OnInit {

  time_left = 45;
  time_left_rounded = 45;
  time_left_pct = 100;
  myTimer;
  color = "primary";
  warning_color = "red";
  five_min_warning = false;
  study_languages: string[] = [];
  langSelect:String='';
  constructor(private dialogRef: MatDialogRef<PatientWelcomeComponent>,
              private router: Router ,
              private timeoutGuard: TimeoutGuard,
              public translate: TranslateService,
              private data: ProqDataService,
              private eventService: EventService) { }
  waiting = false;

  ngOnInit() {
    this.waiting = false;
    this.data.currentLanguageSelected.subscribe((value)=>{
      if(value){
      this.langSelect=value;
      this.translate.use(this.langSelect.toString());
      this.translate.getTranslation(value.toString()).subscribe(values => {});
      }
    });
    // What are my current languages for this study
    this.study_languages = this.data.getSessionValue('supported_languages');
    this.data.setSessionValue('module','patient');
    this.data.setSessionValue('submission_id',0);
  }

  close() {
    if (this.myTimer != null) {clearInterval(this.myTimer);}
     this.dialogRef.close(false);

  }

  confirm() {
    if (this.myTimer != null) {clearInterval(this.myTimer);}

    this.dialogRef.close(true);


  }

  wait() {
    this.waiting = true;
    this.timeoutGuard.checkTimeout();
    this.myTimer = setInterval(() => {
      this.time_left = this.timeoutGuard.getTimeLeft();
      this.time_left_rounded = Math.round(this.time_left);
      this.time_left_pct = ((this.time_left / 45)*100);
      if (this.time_left > 0 && this.time_left <= 5) {this.five_min_warning = true; this.color = "warn";}
      if (this.time_left <=0) {this.router.navigate([`/timeout`]);}
    }, 1000);
  }

  logout() {
    if (this.myTimer != null) {clearInterval(this.myTimer);}
    this.dialogRef.close(true);
    this.router.navigate([`/logout`]);
  }

  reset_timeout() {
    this.timeoutGuard.checkTimeout();
    this.five_min_warning = false;
    this.color = "primary";
  }

  changepage(ob) {
    this.data.setCurrentLanguage(ob.value);
    this.translate.use(ob.value);
    this.data.setModuleElement(undefined);

    var thisstudy = this.data.getSessionValue('study');
    var thissite = this.data.getSessionValue('site');
    var thispatient = this.data.getSessionValue('patient');
    var thisvisit = this.data.getSessionValue('visit');
    var selectedforms = this.data.getSessionValue('forms');
    var thisuser = this.data.getSessionValue('user');

    var screen_name = "PATIENT WELCOME SCREEN";
    if (this.waiting == true) {
      screen_name = "WAITING ON PARTICIPANT QUESTION SCREEN";
    }
    this.eventService.add_event("CHANGE_LANGUAGE","Change Language to " + ob.value + " for " + thisstudy.StudyCode + " " + thissite.Site + " " +
                                                                                                              thisvisit.VisitName + " " + thispatient.Patient, screen_name,thisuser.Username);
  }
}





