import { Routes } from '@angular/router';

import { SelectStudyComponent } from '../main/select-study/select-study.component';
import { SelectSiteComponent } from '../main/select-site/select-site.component';
import { SelectPatientComponent } from '../main/select-patient/select-patient.component';
import { SelectVisitComponent } from '../main/select-visit/select-visit.component';
import { MainComponent } from '../main/main.component';
import { MainPatientComponent} from '../main-patient/main-patient.component';
import { EnterFormComponent } from '../main-patient/enter-form/enter-form.component';
import { SelectFormComponent } from '../main/select-form/select-form.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { PatientFarewellComponent } from '../main-patient/patient-farewell/patient-farewell.component';
import { SplashScreenComponent} from '../splash-screen/splash-screen.component';
import { LogoutComponent } from '../logout/logout.component';
import { AuthGuardService as AuthGuard} from '../guards/auth-guard.service';
import { TimeoutGuardService as TimeoutGuard} from '../guards/timeout-guard.service';
import { CookieGuardService as CookieGuard } from '../guards/cookie-guard.service';
import { TimeoutComponent } from '../timeout/timeout.component';
import { PatientWelcomeComponent } from '../main-patient/patient-welcome/patient-welcome.component';
import { ProqLoginComponent } from '../proq-login/proq-login.component';

export const routes: Routes = [
    { path: 'login', component: ProqLoginComponent, pathMatch: 'full' },
    { path: 'splash', component: SplashScreenComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'select/study', component: SelectStudyComponent, pathMatch: 'full', data: {animation: 'SelectStudyPage'}, canActivate: [AuthGuard, CookieGuard, TimeoutGuard]  },
    { path: 'select/site', component: SelectSiteComponent, pathMatch: 'full', data: {animation: 'SelectSitePage'}, canActivate: [AuthGuard,CookieGuard,TimeoutGuard] },
    { path: 'select/patient', component: SelectPatientComponent, pathMatch: 'full', data: {animation: 'SelectPatientPage'}, canActivate: [AuthGuard, CookieGuard,TimeoutGuard] },
    { path: 'select/visit', component: SelectVisitComponent, pathMatch: 'full', data: {animation: 'SelectVisitPage'}, canActivate: [AuthGuard, CookieGuard,TimeoutGuard] },
    { path: 'select/form', component: SelectFormComponent, pathMatch: 'full', data: {animation: 'SelectFormPage'}, canActivate: [AuthGuard, CookieGuard,TimeoutGuard] },
    { path: 'patient/enter', component: EnterFormComponent, pathMatch : 'full', canActivate: [TimeoutGuard, CookieGuard]},
    { path: 'patient/enter2', component: MainPatientComponent, pathMatch: 'full', canActivate: [TimeoutGuard, CookieGuard]},
    { path: 'patient/welcome', component: PatientWelcomeComponent, pathMatch: 'full', canActivate: [TimeoutGuard, CookieGuard]},
    { path: 'patient/farewell', component: PatientFarewellComponent, pathMatch : 'full'},
    { path: 'logout', component: LogoutComponent, pathMatch : 'full' },
    { path: 'timeout', component: TimeoutComponent, pathMatch : 'full'},
    { path: '',   redirectTo: '/login', pathMatch: 'full'},
    { path: '**', component: PageNotFoundComponent }
];