import { Injectable } from '@angular/core';
import { CoreService } from '../core/core.service';

@Injectable()
export class BaseService {

  constructor(public core: CoreService) {

  }

 
}