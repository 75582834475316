<div mat-dialog-title>
  <div class="">
    <h1 class="logout_confirm_header" style="text-align: center !important;">
      {{'PROQ.GLOBAL.TITLE_LOGOUT_CONFIRMATION' | translate}}</h1>
  </div>
</div>

<div mat-dialog-content class="logout_confirm_contents" style="text-align:center !important;">
  {{'PROQ.GLOBAL.TEXT_LOGOUT_CONFIRMATION' | translate}}

</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">{{'STANDARD_LIBRARY.BUTTON.CANCEL' | translate}}</button>
  <span class="logout-space"></span>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{'STANDARD_LIBRARY.BUTTON.LOGOUT' | translate}}</button>




</div>