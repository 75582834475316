import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Form } from '../shared/form';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';
import { QuestionService } from '../services/question.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService, 
              private questionService: QuestionService,
              private processHTTPMsgService: ProcessHTTPMsgService) { }


  getForms(studyid: number, siteid: number, patientid: number, visitid: number) : Observable<Form[]> {
    return this.dataService.getList(this.baseUrl + '/api/' + studyid.toString() + '/' + siteid.toString() + '/' + patientid.toString() + '/' + visitid.toString())
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCurrentForm(studyid: number, siteid: number, patientid: number, visitid: number, formid: number) : Observable<Form> {
    return this.dataService.getList(this.baseUrl + '/api/' + studyid.toString() + '/' + siteid.toString() + '/' + patientid.toString() + '/' + visitid.toString() + '/' + formid.toString())
    .pipe(catchError(this.processHTTPMsgService.handleError));  
  }




}
