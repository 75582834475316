import {DcapiDetail} from './dcapi-detail';


export class DcapiMaster {
    SUBMISSION_ID: number;
    SUBMITTED_BY: string;
    SUBMITTED_ON: Date;
    STATUS: string;
    STATUS_COMMENT: string;
    BROWSER: string;
    APP_ID: number;
    APP_NAME: string;
    DE_MODE: string;
    CLINICAL_STUDY_ID: number;
    STUDY: string;
    SITE_ID: number;
    SITE: string;
    INVESTIGATOR_ID: number;
    INVESTIGATOR: string;
    PATIENT_POSITION_ID: number;
    PATIENT: string;
    SITE_PT_ID: string;
    VISIT_NAME: string;
    SUBEVENT_NUMBER: number;
    DCI_SHORT_NAME: string;
    DCI_DCM_DATE: string;
    DCI_DCM_TIME: string;
    DCM_SHORT_NAME: string;
    DCM_SUBSET_SN: number;
    DCM_LAYOUT_SN: number;
    DIC_AUDIT_REASON: string;
    DCI_AUDIT_COMMENT: string;
    RECEIVED_DCI_ID: number;
    DOCUMENT_number: string;
    SET_OC_SITE_PT_ID: string;
    DETAILS: DcapiDetail[];
}

