import { Injectable }       from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Form } from '../shared/form';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';


import { RadioQuestion } from '../shared/question-model/question-radio';
import { RadioScaleQuestion } from '../shared/question-model/question-radio-scale';
import { Scale100Question } from '../shared/question-model/question-radio-scale100';
import { QuestionBase }     from '../shared/question-model/question-base';
import { TextboxQuestion }  from '../shared/question-model/question-textbox';
import { DcapiMaster } from '../shared/submission-model/dcapi-master';
import { DcapiDetail } from '../shared/submission-model/dcapi-detail';
import { RadioHorizontalQuestion } from '../shared/question-model/question-radio-horizontal';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {

  private baseUrl: string = 'proq_api';
  thisform: Form;

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService) { }


  setQuestions(pForm: Form) {
    var arr = [];
    var thisquestion;
    var qRequired = true;
    this.thisform = pForm;

    var q_counter = 0;
    for ( var i=0; i< pForm.ProqQuestions.length;i++) {

      // Required
      if (pForm.ProqQuestions[i].OcQuestionHelpText != undefined && 
        pForm.ProqQuestions[i].OcQuestionHelpText.includes("PROQ:OPTIONAL")) {
          qRequired = false;
      }
      else {
        qRequired = true;
      }

      if (pForm.ProqQuestions[i].OcQuestionHelpText != undefined &&
        pForm.ProqQuestions[i].OcQuestionHelpText.includes("PROQ:SCALE100")) {
          thisquestion = new Scale100Question({
            key: pForm.ProqQuestions[i].ProqFormQuestionId,
            label: pForm.StudyCode + '.' + pForm.OcDcmShortName + "__" + pForm.OcDcmSubsetSn + '.QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + "__" + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.PROMPT',
            //label: pForm.ProqQuestions[i].ProqQuestionPrompt,
            required: qRequired,
            order: q_counter+1
          });

          arr.push(thisquestion);
          q_counter++;
      }
      else if (pForm.ProqQuestions[i].OcQuestionType == 'STANDARD') {  
        if (pForm.ProqQuestions[i].OcQuestionDataTypeCode == "CHAR" && 
            pForm.ProqQuestions[i].ProqQuestionValues &&
            pForm.ProqQuestions[i].ProqQuestionValues.length > 0) {

              var theseoptions = [];

              for (var j=0; j< pForm.ProqQuestions[i].ProqQuestionValues.length;j++) {
                theseoptions.push({key: pForm.ProqQuestions[i].ProqQuestionValues[j].OcDvgValueShortValue,
                              value: pForm.StudyCode + '.' + pForm.OcDcmShortName + '__' + pForm.OcDcmSubsetSn + '.' + 'QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + '__' + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.VALUE__' + pForm.ProqQuestions[i].ProqQuestionValues[j].OcDvgValueShortValue});
              }
                  
              if (pForm.ProqQuestions[i].OcQuestionHelpText != undefined &&
                  pForm.ProqQuestions[i].OcQuestionHelpText.includes("PROQ:SCALE")) {
                thisquestion = new RadioScaleQuestion({
                  key: pForm.ProqQuestions[i].ProqFormQuestionId,
                  label: pForm.StudyCode + '.' + pForm.OcDcmShortName + "__" + pForm.OcDcmSubsetSn + '.QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + "__" + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.PROMPT',
                  //label: pForm.ProqQuestions[i].ProqQuestionPrompt,
                  required: qRequired,
                  order: q_counter+1,
                  options: theseoptions
                });
              
              }
              else if (pForm.ProqQuestions[i].OcQuestionHelpText != undefined &&
                pForm.ProqQuestions[i].OcQuestionHelpText.includes("PROQ:HORIZONTAL")) {
                  thisquestion = new RadioHorizontalQuestion({
                    key: pForm.ProqQuestions[i].ProqFormQuestionId,
                    label: pForm.StudyCode + '.' + pForm.OcDcmShortName + "__" + pForm.OcDcmSubsetSn + '.QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + "__" + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.PROMPT',
//                    label: pForm.ProqQuestions[i].ProqQuestionPrompt,
                    required: qRequired,
                    order: q_counter+1,
                    options: theseoptions
                  });
                }
              else {
                thisquestion = new RadioQuestion({
                  key: pForm.ProqQuestions[i].ProqFormQuestionId,
                  label: pForm.StudyCode + '.' + pForm.OcDcmShortName + "__" + pForm.OcDcmSubsetSn + '.QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + "__" + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.PROMPT',
//                  label: pForm.ProqQuestions[i].ProqQuestionPrompt,
                  required: qRequired,
                  order: q_counter+1,
                  options: theseoptions
                });
              }
            arr.push(thisquestion);
          }
        else if (pForm.ProqQuestions[i].OcQuestionDataTypeCode == "CHAR") {
          thisquestion = new TextboxQuestion({
              key: pForm.ProqQuestions[i].ProqFormQuestionId,
              label: pForm.StudyCode + '.' + pForm.OcDcmShortName + "__" + pForm.OcDcmSubsetSn + '.QUESTIONS.' + pForm.ProqQuestions[i].OcQuestionName + "__" + pForm.ProqQuestions[i].OcQuestionOccurrenceSn + '.PROMPT',
              //label: pForm.ProqQuestions[i].OcQuestionDefaultPrompt,
              value: '',
              required: qRequired,
              order: q_counter+1
          });

          arr.push(thisquestion);

        }
        q_counter++;
      }
    } 

    let questions: QuestionBase<any>[] = arr;

    return questions.sort((a, b) => a.order - b.order);
  }



}


