import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Patient } from '../shared/patient';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';


@Injectable({
  providedIn: 'root'
})
export class PatientService {


  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService) { }
  
  
  getPatients(studyid:number,siteid:number) : Observable<Patient[]> { 
    return this.dataService.getList(this.baseUrl + '/api/' + studyid.toString() + '/' + siteid.toString())
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}


