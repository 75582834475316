
export class Form {
    StudyId: number;
    StudyCode: string;
    ProqFormId: number;
    FormName: string;
    FormDescription: string;
    FormStatus: string;
    FormHeader: string;
    FormFooter: string;
    FormInstruction: string;
    OcDciId: number;
    OcDciShortName: string;
    OcDcmId: number;
    OcDcmName: string;
    OcDcmShortName: string;
    OcDcmSubsetSn: number;
    OcDcmLayoutSn: number;
    OcDcmHelpText: string;
    FormSelected: boolean;
    ProqQuestions: [
        {
           ProqFormQuestionId: number;
           ProqQuestionPrompt: string;
           OcDcmQuestionGroupId: number;
           OcQuestionGroupName: string,
           OcQuestionGroupHelpText: string,
           OcDcmQuestionId: number;
           OcQuestionName: string;
           OcQuestionOccurrenceSn: number;
           OcQuestionDataTypeCode: string;
           OcQuestionDateTimeType: string;
           OcQuestionDateTimeFormat: string;
           OcQuestionLength: number;
           OcQuestionDecimalPlaces: number;
           OcQuestionMandatoryFlag: string;
           OcQuestionDefaultPrompt: string;
           OcQuestionSasName: string;
           OcQuestionSasLabel: string;
           OcQuestionDisplaySn: number;
           OcQuestionHelpText: string;
           OcQuestionType: string;
           ProqQuestionResponse: string;
           ProqQuestionValues: [
               {
                   ProqFormQuestionId: number,
                   OcDvgName: string;
                   OcDvgDomain: string;
                   OcDvgDisplayTypeCode: string;
                   OcDvgCheckboxLayoutCode: string;
                   OcDvgValueShortValue: string;
                   ProqShortValue: string;
                   OcDvgValueLongValue: string;
                   ProqLongValue: string;
                   OcDvgValueDisplaySn: number;
                   ProqValue: string;
               }
           ] 
        }
    ]
}
