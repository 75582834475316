export const environment = {
    production: false,
    name: 'prod',
    authority: '',
    resource: 'https://clinicalstudies.abbott.com/api/ext/proq/',
    authResource: 'https://clinicalstudies.abbott.com/auth/authCookies',
    clientId: '88887c65a7754e739dc6e9437902c30c',
    appKey: 'AwJi6P3'
};

