import { Injectable } from '@angular/core';
import { Configuration } from './configuration.service';
import { CoreService } from './core.service';
import { DataService } from './data.service';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggingService {

  constructor(private dataService: DataService, private core: CoreService,
              private config: Configuration, private storage: StorageService) { }

  createLoginEvent() {

    if (environment.name === 'local') {
      return;
    }

    //This is a get request even though it's posting because the api is set as a http get
    this.dataService.getList('app/' + this.config.settings.appKey + '/login', null, true)
      .subscribe(
        result => {
          this.storage.store("logId", result);
        },
        error => {
          console.log(error);
        }
      );
  }

  createLogoutEvent() {

    if (environment.name === 'local') {
      return;
    }

    let logId = this.storage.retrieve("logId");

    //This is a get request even though it's posting because the api is set as a http get
    this.dataService.getList('app/' + this.config.settings.appKey + '/logout/' + logId, null, true)
      .subscribe(
        result => {
          window.close();
        },
        error => {
          console.log(error);
        }
      );
  }

  createRecordExecuteEvent(moduleKey: string) {

    if (environment.name === 'local') {
      return;
    }

    let logId = this.storage.retrieve("logId");

    //This is a get request even though it's posting because the api is set as a http get
    this.dataService.getList('app/' + this.config.settings.appKey + '/recordExecute/' + logId + '/' + moduleKey, null, true)
      .subscribe(
        result => {

        },
        error => {
          console.log(error);
        }
      );
  }

}
