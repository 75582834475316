<div class="splash-screen" *ngIf="show_splash_screen == true">
  <div class="proq">
    <h1>PRO-Q</h1>
  </div>
  <div class="proq-title">
    <h2><u>P</u>atient <u>R</u>eported <u>O</u>utcome <u>Q</u>uestionnaires</h2>
  </div>
  <div class="spinner">
    <div class="cube1"></div>
    <div class="cube2"></div>
  </div>
</div>