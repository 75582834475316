import { Component, OnInit } from '@angular/core';
import { ProqDataService } from '../services/data.service';
import {EventService} from '../services/event.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private data: ProqDataService,
              private eventService: EventService) { }

  ngOnInit() {
    this.data.setHeaderDisplay(false);
  }

}
