import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient  } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import { AppComponent } from './app.component';
import { CoreService, DataService, Configuration, SecurityService, StorageService, LoggingService } from '../template-shared/services/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainComponent } from './main/main.component';
import { MaterialModule } from '../app-shared/modules/material/material.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SelectStudyComponent } from './main/select-study/select-study.component';
import { SelectSiteComponent } from './main/select-site/select-site.component';
import { SelectPatientComponent } from './main/select-patient/select-patient.component';
import { SelectVisitComponent } from './main/select-visit/select-visit.component';
import { AppRoutingModule } from './app-routing/app-routing.component';
import { RcConfirmationComponent } from './main/rc-confirmation/rc-confirmation.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MainPatientComponent } from './main-patient/main-patient.component';
import { EnterFormComponent } from './main-patient/enter-form/enter-form.component';
import { SelectFormComponent } from './main/select-form/select-form.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { DatePipe } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-question/dynamic-form-question.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PatientConfirmationComponent } from './main-patient/patient-confirmation/patient-confirmation.component';
import { PatientFarewellComponent } from './main-patient/patient-farewell/patient-farewell.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { TemporarilyUnavailableComponent } from './temporarily-unavailable/temporarily-unavailable.component';
import { LogoutComponent } from './logout/logout.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { PatientWelcomeComponent } from './main-patient/patient-welcome/patient-welcome.component';
import { ProqLoginComponent } from './proq-login/proq-login.component';
import { CookieService } from 'ngx-cookie-service';
import { CookieLawModule } from 'angular2-cookie-law';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DialogLogoutConfirmComponent } from './dialog-logout-confirm/dialog-logout-confirm.component';
import { ProqDataService } from './services/data.service';
import {TranslateService} from '@ngx-translate/core';

// AoT Function for Factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "assets/i18n/",".json");
}
export function appInitFactory(data: ProqDataService) {
  return ()=>data.setAllSupportedLanguages();
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SelectStudyComponent,
    SelectSiteComponent,
    SelectPatientComponent,
    SelectVisitComponent,
    RcConfirmationComponent,
    MainPatientComponent,
    EnterFormComponent,
    SelectFormComponent,
    CallbackPipe,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    PageNotFoundComponent,
    PatientConfirmationComponent,
    PatientFarewellComponent,
    SplashScreenComponent,
    TemporarilyUnavailableComponent,
    LogoutComponent,
    TimeoutComponent,
    PatientWelcomeComponent,
    ProqLoginComponent,
    DialogLogoutConfirmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatDialogModule,
    MatProgressBarModule,
    CookieLawModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CoreService,
    DataService,
    Configuration,
    SecurityService,
    StorageService,
    LoggingService,
    CallbackPipe,
    CookieService,
    { provide: APP_INITIALIZER, useFactory: appInitFactory, deps: [ProqDataService], multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    DatePipe,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  entryComponents:[RcConfirmationComponent, PatientConfirmationComponent, DialogLogoutConfirmComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
