import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ProqDataService } from '../services/data.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieGuardService {

  private rtcookie: boolean;
  private atcookie: boolean;

  constructor(private dataservice: ProqDataService, 
              private _router: Router,
              private cookieService: CookieService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   // Checking Time Out
   return this.checkAuthCookies();
  }

  // Same as canActivate, but checked explicitely
  checkAuthCookies() {

    if (window.location.href.includes("localhost")) { return true;} // escape hatch for local testing

    this.rtcookie = this.cookieService.check('cas-rt');

    if (this.rtcookie) {
      return true;
    }
    else {
      this._router.navigate(['login']);
    }

   }

}


