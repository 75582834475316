import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Parameter } from '../../models/parameter';
import { BaseService } from '../base/base.service';

@Injectable()
export class DataService extends BaseService {


  private url: string = '';
  

  getList(controller: string, parameters?: Parameter[], auth: boolean = false): Observable<any> {

    this.url = auth === false ? this.core.makeUrl(controller) : this.core.makeAuthUrl(controller);
    parameters = parameters || [];

    return this.core
      .get(this.url, parameters)
      .pipe(map(r => {
        return r;
      }))
  }


  getRequest(controller: string, id: number): Observable<any> {

    this.url = this.core.makeUrl(controller);

    return this.core.get(this.url + '/' + id)
      .pipe(map( r=> {
        const data = r;
        return <any>data
      }))
  }

  public postRequest(controller: string, data: any) {

    this.url = this.core.makeUrl(controller);

    return this.core
      .post(this.url, data);
  }

  public putRequest(controller: string, id: number, data: any) {

    this.url = this.core.makeUrl(controller);

    return this.core
      .put(this.url + '/' + id, data);
  }

  postAuthRequest = function (controller, data) {
    this.url = this.core.makeAuthUrl(controller);
    return this.core
      .post(this.url, data);
  }

  public deleteRequest(controller: string, id: number) {

    this.url = this.core.makeUrl(controller);

    return this.core
      .delete(this.url + '/' + id);
  }

}
