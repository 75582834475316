import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Visit } from '../shared/visit';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService) { }


  getVisits(studyid: number, siteid: number, patientid: number) : Observable<Visit[]> {
    return this.dataService.getList(this.baseUrl + '/api/' + studyid.toString() + '/' + siteid.toString() + '/' + patientid.toString())
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}


