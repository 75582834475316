import { Injectable } from '@angular/core';
import { DataService } from '../../template-shared/services/core/index';
import { Site } from '../shared/site';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class CheckstudyaccessService {

  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService, 
              private processHTTPMsgService: ProcessHTTPMsgService) { }


  checkStudyAccess() : Observable<Site[]> {
    return this.dataService.getList(this.baseUrl + '/access')
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }}
