<div class="container-fluid" style="margin-left: -30px; margin-right: 30px; height:100%">
  <mat-card *ngIf="formCount > 0" >
    <button class="mat-raised-button mat-primary select-continue-button" (click)="openDialog();" [@bullseye]="show_bullseye ? true : false" >{{'STANDARD_LIBRARY.BUTTON.CONTINUE' | translate | titlecase}}</button>
  </mat-card>
<div class="row no-gutters align-items-stretch" >
    <div class="col-12 col-sm-6 col-md-8 d-flex align-items-stretch">
      <div class="card holder-lft">
        <div class="col-xs-12 col-sm-6" *ngFor="let thisform of forms | callback: FilterForms">
          <mat-card class="form" (click)="NewForm(thisform);">
            <mat-card-title class="form-mat-card-title"><span class="fa fa-file"></span> {{thisform.FormDescription}}</mat-card-title>
            <mat-card-subtitle>{{'PROQ.RC_GENERIC.QUESTIONNAIRE' | translate | uppercase}} {{thisform.FormName}}</mat-card-subtitle>
          </mat-card>
        </div>
  
      </div> <!-- END CARD HOLDER -->
    </div> <!-- COL -->

    <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
      <div class="holder-rt">  
      <mat-card class="title-selected selections">{{'PROQ.RC_GENERIC.SELECTIONS' | translate}}</mat-card>  
      <mat-card class="mat-card-selected study study-leave" [routerLink]="['/select/study']" routerLinkActive="active">
          <mat-card-title class="title-selected"><span class="fa fa-vial"></span> {{thisstudy.StudyTitle}}</mat-card-title>
          <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_STUDY' | translate | uppercase}} {{thisstudy.StudyCode}}</mat-card-subtitle>
        </mat-card>
        <mat-card class="mat-card-selected site study-leave site-leave" [routerLink]="['/select/site']" routerLinkActive="active">
          <mat-card-title class="title-selected"><span class="fa fa-hospital"></span> {{thissite.SiteName}}</mat-card-title>
          <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_SITE' | translate | uppercase}} {{thissite.Site}}</mat-card-subtitle>
        </mat-card>
        <mat-card class="mat-card-selected patient study-leave site-leave patient-leave" [routerLink]="['/select/patient']" routerLinkActive="active">
          <mat-card-title class="title-selected"><span class="fa fa-user"></span> {{thispatient.StudySite}}-{{thispatient.Patient}}</mat-card-title>
          <mat-card-subtitle>
            <div class="row" *ngIf="thispatient.EnrollmentDate">
              <div class="col-xs-12">{{'STANDARD_LIBRARY.TEXT_ENROLLED' | translate | uppercase}} {{thispatient.EnrollmentDate | date:'dd-MMM-yyyy' }}</div>
            </div>
            <div class="row" *ngIf="!thispatient.EnrollmentDate">
              <div class="col-xs-12">{{'STANDARD_LIBRARY.TEXT_PATIENT' | translate | uppercase}} {{thispatient.Patient}}</div>
            </div>
          </mat-card-subtitle>
        </mat-card>
        <mat-card class="mat-card-selected visit selected-visit study-leave site-leave patient-leave visit-leave" [routerLink]="['/select/visit']" routerLinkActive="active">
          <mat-card-title class="title-selected"><span class="fa fa-calendar"></span> {{thisvisit.VisitDescription}}</mat-card-title>
          <mat-card-subtitle>{{'STANDARD_LIBRARY.TEXT_VISIT' | translate | uppercase}} {{thisvisit.VisitName}}</mat-card-subtitle>
          <mat-card-content *ngIf="thisvisit.VisitStatus" fxFlexAlign="center">
            <div class="row">
              <div class="col-sm-5" *ngIf="thisvisit.VisitLowerBoundDt">
                {{'STANDARD_LIBRARY.TEXT_EXPECTED' | translate}} {{thisvisit.VisitLowerBoundDt | date:'dd-MMM-yyyy'}} to {{thisvisit.VisitUpperBoundDt | date:'dd-MMM-yyyy'}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card @slideInOutAnimation *ngFor="let thisform of forms | callback: FilterSelectedForms" class="mat-card-selected selected-form  study-leave site-leave patient-leave form-leave" (click)="RemoveForm(thisform);">
          <mat-card-title class="title-selected"><span class="fa fa-file"></span> {{thisform.FormDescription}}</mat-card-title>
          <mat-card-subtitle>{{'PROQ.RC_GENERIC.QUESTIONNAIRE' | translate | uppercase}} {{thisform.FormName}}</mat-card-subtitle>
        </mat-card>
      </div>
    </div> <!-- MAIN COL #2 -->     

  </div> <!-- BIG ROW -->
</div> <!-- FLUID CONTAINER -->

<div fxFlex *ngIf="errMess">
  <h2>Error</h2>
  <h4>{{errMess}}</h4>
</div>