import { Injectable, EventEmitter } from '@angular/core';
import { Parameter } from '../../models/parameter';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


import { Configuration } from './configuration.service';
import * as moment from 'moment';

@Injectable()
export class CoreService {

  constructor(private http: HttpClient, private config: Configuration) {}

  workingUpdated:EventEmitter<any> = new EventEmitter();

  private isWorking = false;

  setWorking(input: boolean){
    this.isWorking = input;
    this.workingUpdated.emit(input);
  }

  getWorking(){
    return this.isWorking;
  }

  static attachParameters(url: string, params?: Parameter[]): string {

    if (!params) return url;

    const l = params.length;

    for (let i = 0; i < l; ++i) {
      const prefix = i ? '&' : '?';
      url += prefix + params[i].id + '=' + params[i].value;
    }

    return url;
  }

  makeUrl(command: string, params?: Parameter[]): string {

    let url = this.config.settings.resource + command;

    url = CoreService.attachParameters (url, params);

    return url;
  }

  makeAuthUrl(command: string): string {

    let url = this.config.settings.authResource;

    url = CoreService.attachParameters (url);

    return url;
  }

  get(url: string, data?: Parameter[]): Observable<any> {
    url = CoreService.attachParameters(url, data);
    return this.http.get(url);
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(url, data);
  }

  delete(url: string, data?: Parameter[]): Observable<any> {
    url = CoreService.attachParameters(url, data);
    return this.http.delete(url);
  }

  static isObject(input: any) {
    if (input === null) { return false; }
    return ( (typeof input === 'function') || (typeof input === 'object') );
  }

  static isArray(input: any) {
    if (input === null) { return false; }
    return Array.isArray(input);
  }

  static formatDate(input: string, inputFormat = '', outputFormat = ''): string {

    if (!input) return '';

    inputFormat = inputFormat || 'YYYY-MM-DDTHH:mm:ss';
    outputFormat = outputFormat || 'DD-MMM-YYYY';

    let date = moment(input, inputFormat);

    if (!date.isValid()) {
      date = moment(new Date(input));
    }

    return !date.isValid() ? input : date.format(outputFormat);
  }

  static toDate(input: any, inputFormat = ''): Date {

    const d = moment(new Date(input));

    inputFormat = inputFormat || 'DD-MMM-YYYY';

    const date = moment(input, inputFormat);

    return d.isValid() && date.isValid()
      ? date.toDate()
      : d.isValid()
      ? d.toDate()
      : null;
  }

  static flatten(input: string[], separator: string = ',', key?: string): string {

    if (!input || !separator || !key) {
      return '';
    }

    const arr = [];
    const l = input.length;

    for (let i = 0; i < l; ++i) {
      arr.push(input[i][key]);
    }

    return arr.join(separator);
  }

  static checkForNull(input: string): string {
    return !(!input || input === ', ') ? input : 'N/A';
  }

}
