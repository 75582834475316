import { Component, OnInit } from '@angular/core';
import { ProqDataService } from '../../services/data.service';
import { QuestionService } from '../../services/question.service';
import { QuestionBase } from '../../shared/question-model/question-base';

import { Study } from '../../shared/study';
import { Site } from '../../shared/site';
import { Patient } from '../../shared/patient';
import { Visit } from '../../shared/visit';
import { Form } from '../../shared/form';
import { User } from '../../shared/user';
import { FormService } from '../../services/form.service';
import { SlideInOutAnimation, Bullseye } from '../../shared/animations';

import { CookiesService } from '../../services/cookies.service';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientWelcomeComponent } from '../patient-welcome/patient-welcome.component';

import { Router } from '@angular/router';

@Component({
  selector: 'app-enter-form',
  templateUrl: './enter-form.component.html',
  styleUrls: ['./enter-form.component.scss'],
  providers: [QuestionService],
  animations: [SlideInOutAnimation]
})
export class EnterFormComponent implements OnInit {
  thisstudy: Study;
  thissite: Site;
  thispatient: Patient;
  thisvisit: Visit;
  thisforms: Form[];
  errMess: string;
  currentForm: Form;
  today: Date;
  thispage: number = 0;
  cookiemsg: any[];
  thisuser: User;

  questions:  QuestionBase<any>[];

  constructor(private data: ProqDataService,
              private formService: FormService,
              private questionService: QuestionService,
              private cookiesService: CookiesService,
              private dialog: MatDialog,
              private router: Router) { }


  ngOnInit() {

    var patientConfirmed = this.data.getSessionValue('patient-confirmed');
    if (patientConfirmed == null || patientConfirmed == "" || patientConfirmed == false) {
      // Note: setTimeout avoids error ExpressionChangedAfterItHasBeenCheckedError
      setTimeout((x) => {this.openDialog()},1);
      this.data.setSessionValue('module','patient');

    }

    this.data.setHeaderDisplay(true);
    this.data.setLogoutDisplay(true);
    this.data.setModuleElement(undefined);

    this.data.currentStudy.subscribe(selectedstudy => {
      this.thisstudy = selectedstudy;
      if (this.thisstudy.StudyId == undefined) {
        this.thisstudy = this.data.getSessionValue('study');
      }
    });

    this.data.currentSite.subscribe(selectedsite => {
      this.thissite = selectedsite;
      if (this.thissite.SiteId == undefined) {
        this.thissite = this.data.getSessionValue('site');
      }
    });

    this.data.currentPatient.subscribe(selectedpatient => {
      this.thispatient = selectedpatient;
      if (this.thispatient.PatientId == undefined) {
        this.thispatient = this.data.getSessionValue('patient');
      }
    });

    this.data.currentVisit.subscribe(selectedvisit => {
      this.thisvisit = selectedvisit;
      if (this.thisvisit.VisitId == undefined) {
        this.thisvisit = this.data.getSessionValue('visit');
      }
    });

    this.data.currentForms.subscribe(selectedForms => {
      this.thisforms = selectedForms;
      if (this.thisforms[0] == undefined) {
        this.thisforms = this.data.getSessionValue('forms');
      }
    });

    this.data.currentUser.subscribe(currentUser => {
      this.thisuser = currentUser;
      if (this.thisuser.Username == undefined) {
        this.thisuser = this.data.getSessionValue('user');
      }
    });


    this.today = new Date();

    this.formService.getCurrentForm(this.thisstudy.StudyId,
                                    this.thissite.SiteId,
                                    this.thispatient.PatientId,
                                    this.thisvisit.VisitId,
                                    this.thisforms[0].ProqFormId)
    .subscribe((form) => {
                this.currentForm = form[0];
                if (this.currentForm == undefined){
                  // Get it from the session storage
                  this.currentForm = this.data.getSessionValue('form');
                }
                this.questions = this.questionService.setQuestions(this.currentForm);

                this.data.setCurrentForm(this.currentForm);

                if (this.currentForm == null) {
                  // Something went wrong, log the user out
                  this.router.navigate([`/logout`]);
                }
              },
      errmess => this.errMess = <any>errmess);


      this.disableScroll();


  }


  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;

    const dialogRef = this.dialog.open(PatientWelcomeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {}
    );

  }

  preventDefault(e){
    e.preventDefault();
  }

  disableScroll(){
      document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
  }

  enableScroll(){
      document.body.removeEventListener('touchmove', this.preventDefault);
  }




}


