export class UserEvent {
  USER_EVENT_ID: number;
  EVENT_CODE: string;
  EVENT_INFO: string;
  EVENT_COMMENT: string;
  EVENT_TS: Date;
  EVENT_BY_ID: number;
  EVENT_BY: string;
}

