import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Study} from '../shared/study';
import {Site} from '../shared/site';
import {Patient} from '../shared/patient';
import {Visit} from '../shared/visit';
import {Form} from '../shared/form';
import {User} from '../shared/user';
import {DcapiMaster} from '../shared/submission-model/dcapi-master';
import {DcapiDetail} from '../shared/submission-model/dcapi-detail';
import {UserEvent} from '../shared/userevent';
import {DataService} from '../../template-shared/services/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private baseUrl: string = 'proq_api';

  constructor(private dataService: DataService) { }


  add_event(pEventCode: string, pEventInfo: string, pEventComment: string, pEventBy: string) {

    var thisevent = new UserEvent();

    thisevent.EVENT_BY = pEventBy;
    thisevent.EVENT_CODE = pEventCode;
    thisevent.EVENT_INFO = pEventInfo;
    thisevent.EVENT_COMMENT = pEventComment;

    this.dataService.postRequest(this.baseUrl + '/log_user_event', thisevent)
      .subscribe(
        (x) => {
        },
        error => {

          console.log("ERROR: " + JSON.stringify(error.message));

        }
      );

  }


}

