<div class="button-bar">
    <div *ngIf="current_page > 1">
      <button mat-button-raised class="back-button" (click)="back();">
          <svg style="width:30px;height:30px; position: relative; top:.4em;" viewBox="0 0 24 24">
              <path fill="#F0F0F0" d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
          </svg>
          {{'STANDARD_LIBRARY.BUTTON.PREVIOUS' | translate | titlecase}}
      </button>
    </div>
    <div *ngIf="questions.length >= current_page">
      <button [@bullseye]="show_bullseye ? true : false" mat-button-raised class="continue-button" [disabled]="loading" (click)="continue(questions);">{{'STANDARD_LIBRARY.BUTTON.NEXT' | translate | titlecase}}
          <svg style="width:30px;height:30px; position:relative; top:.4em;" viewBox="0 0 24 24">
              <path fill="#F0F0F0" d="M16,18H18V6H16M6,18L14.5,12L6,6V18Z" />
          </svg>
          <i *ngIf="loading" class="spinner"></i>
      </button>
    </div>
    <div *ngIf="current_page > questions.length" class="form-row">
      <button [@bullseye]="show_bullseye ? true : false" mat-button-raised type="submit" (click)="continue(questions)" class="save-button">{{'STANDARD_LIBRARY.BUTTON.SAVE' | translate | titlecase}}
        <i class="fas fa-save" style="width: 30px; height: 30x;"></i>
      </button>
    </div>

  </div>


  <div class="card form-frame">
    <div *ngIf="current_page > 1" class="formNameHeading">{{thisform.FormDescription}}</div>

  <form [formGroup]="form">

    <div class="row" *ngIf="current_page == 1">
      <div class="col-sm-9">
          Date: {{today | date:'d-MMM-yyyy' : 'UTC'}}
      </div>
    </div>


    <div class="row" *ngIf="current_page == 1">
      <div class="col-sm-12 formheader">
        <div *ngIf="thisform.FormHeader" class="formheader">
          <!--<span [innerHTML] = "thisform.FormHeader"></span>-->
          <span [innerHTML] = "(thisform.StudyCode + '.' + thisform.OcDcmShortName + '__' + thisform.OcDcmSubsetSn  + '.HEADER') | translate"></span>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="current_page == 1">
      <div class="col-sm-12">
        <div *ngIf="thisform.FormInstruction" class="forminstruction">
          <!--<p [innerHTML] = "thisform.FormInstruction"></p>-->
          <p [innerHTML] = "(thisform.StudyCode + '.' + thisform.OcDcmShortName + '__' + thisform.OcDcmSubsetSn +'.INSTRUCTION') | translate"></p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div *ngIf="current_page > 1">
          <div *ngFor="let question of questions; let i = index;">
            <div @animationOption2 class="card form-row" *ngIf="show_question(question);">
              <app-question  [question]="question" [form]="form" (hit_bullseye)="hit_bullseye($event)" (bye_bullseye)="bye_bullseye($event)" (autoprogress)="continue($event)"></app-question>
              <span class="question-counter">
                {{'STANDARD_LIBRARY.TEXT_QUESTION' | translate | uppercase}} {{i+1}} {{'STANDARD_LIBRARY.TEXT_OF' | translate | lowercase}} {{questions.length}}
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="current_page==1">
      <div class="col-sm-12">
        <div *ngIf="thisform.FormFooter" class="formfooter">
          <p [innerHTML] = "(thisform.StudyCode + '.' + thisform.OcDcmShortName + '__' + thisform.OcDcmSubsetSn + '.FOOTER') | translate"></p>
        </div>
      </div>
    </div>

  </form>
</div>
