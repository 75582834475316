
import { Component, Input } from '@angular/core';
import { FormGroup }        from '@angular/forms';

import { QuestionBase }     from '../shared/question-model/question-base';
import { Output, EventEmitter } from '@angular/core'; 

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
})
export class DynamicFormQuestionComponent {
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  @Output() autoprogress: EventEmitter<String> = new EventEmitter<String>();
  @Output() hit_bullseye: EventEmitter<String> = new EventEmitter<String>();
  @Output() bye_bullseye: EventEmitter<String> = new EventEmitter<String>();


  
  lastValue = null;
  

  get isValid() { return this.form.controls[this.question.key].valid; }

  AutoProgress(event) {
    // Commenting out AutoProgress for now
   /* setTimeout(() => {
      this.autoprogress.emit(null);
    }, 150);
    */
    
  }

  HitBullseye(event) {
    this.hit_bullseye.emit(null);
  }

  getColClass(question_options: [any]) {
    var col_iterator = Math.floor(12/question_options.length);
    if (col_iterator < 1) {col_iterator = 1;}
    var thisclass = 'col-sm-'+col_iterator.toString() + ' h-col' + question_options.length;
    return thisclass;
  }

  getPaddingCols(question_options: [any]) {
    var col_iterator = Math.floor(12/question_options.length);
    if (col_iterator < 1) {col_iterator = 1;}
    var col_padding = (12 - (col_iterator*question_options.length)) / 2;
    var padding_cols = [];
    
    for(var i = 0; i< col_padding; i++) {
      padding_cols[i] = 'col-sm-1';  
    }

    return padding_cols;
  }

  ToggleClick(radioid, clickedvalue) {

    if (clickedvalue == this.lastValue) {
        setTimeout(() =>  {
            this.form.controls[radioid].reset(null);
            this.form.controls[radioid].setValue("");
            this.bye_bullseye.emit(null);
        });
        this.lastValue = null;
    }
    else {
        this.lastValue = clickedvalue;
    }
  }


}

