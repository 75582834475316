<div [formGroup]="form" class="proq-form">
    <label [attr.for]="question.key" class="question-label" [innerHTML] = "question.label | translate"></label>
    <!--<label [attr.for]="question.key" class="question-label" [innerHTML] = "question.label"></label>-->
    <div [ngSwitch]="question.controlType">
  
      <!-- TEXT BOX QUESTION -->
      <input *ngSwitchCase="'textbox'" [formControlName]="question.key"
              [id]="question.key" [name]="question.key" [type]="question.type">
  
      <!-- STANDARD RADIO BUTTON - VERTICAL (change)="toggleChange($event)-->
      <mat-radio-group [id]="question.key" [name]="question.key" *ngSwitchCase="'radio'" [formControlName]="question.key" (change)="HitBullseye($event); AutoProgress($event);">
        <label *ngFor="let opt of question.options">
          <mat-radio-button class="standard-radio-button" [value]="opt.key" (click)="ToggleClick(question.key, opt.key)">{{(opt.value) | translate }}</mat-radio-button></label>
      </mat-radio-group>

      <!-- SCALE QUESTION -->
      <mat-radio-group [id]="question.key" [name]="question.key" *ngSwitchCase="'scale'" [formControlName]="question.key" (change)="HitBullseye($event); AutoProgress($event)">
        <div class="row scale-row">
            <label *ngFor="let opt of question.options; let idx = index">
              <div class="col-sm-1 scale-col" >
                <mat-radio-button class="radio-scale-button" [value]="opt.key" (click)="ToggleClick(question.key, opt.key)">{{opt.key}}</mat-radio-button>
                <div class="scale-label" *ngIf="idx == 0 || idx == question.options.length-1">{{opt.value | translate}}</div>
              </div>
            </label>
        </div> 
      </mat-radio-group>

      <!-- SCALE 100QUESTION -->
      <mat-slider class="slider-100" min="0" max="100" step="1" value="0" thumbLabel tickInterval="1" [id]="question.key" *ngSwitchCase="'scale100'" [formControlName]="question.key" (change)="HitBullseye($event); AutoProgress($event)"></mat-slider>


      <!-- RADIO BUTTON - HORIZONTAL-->
      <mat-radio-group [id]="question.key" [name]="question.key" *ngSwitchCase="'radio-horizontal'" [formControlName]="question.key" (change)="HitBullseye($event); AutoProgress($event)">
          <div class="row  justify-content-center radio-horizontal">
          <div [className]="getColClass(question.options)" *ngFor="let opt of question.options; let idx = index">
              <label>
                  <mat-radio-button  class="radio-h-button" [value]="opt.key" (click)="ToggleClick(question.key, opt.key)">{{opt.value | translate}}</mat-radio-button>
              </label>
            </div>
          </div> 
      </mat-radio-group>
      <div style="color:red;padding:1px;font-weight:bolder;" class="errorMessage" *ngIf="question.key && form.get(question.key.toString()).errors && (form.get(question.key.toString()).dirty || form.get(question.key.toString()).touched)">
        {{'PROQ.ERROR.ANSWER_REQUIRED' | translate}}
      </div>
    </div> 
  </div>

