
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ProqDataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutGuardService implements CanActivate {

  timeout = 45; // Timeout in minutes

  constructor(private dataservice: ProqDataService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   // Checking Time Out
   return this.checkTimeout();
  }

  // Same as canActivate, but checked explicitely
  checkTimeout() {
    // Checking Time Out
    if (this.dataservice.getLastAccessTime() == -500 ||
        this.dataservice.getLastAccessTime() > this.timeout) {
      this._router.navigate(['/timeout']);
      //return false;
    }
    else {
      // Update Timeout Token and continue
     this.dataservice.updateAccessTime();
     return true;
 
    }
   }

   getTimeLeft() {
     return this.timeout - this.dataservice.getLastAccessTime();

   }

}


