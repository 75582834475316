import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Configuration } from './configuration.service';
import {CoreService} from './core.service';

export enum STORAGE {
  local, session
}

export interface IWebStorage {
  getItem(key: string): any;
  setItem(key: string, value: string);
  removeItem(key: string);
}

const STORAGECACHE = {[STORAGE.local]: {}, [STORAGE.session]: {}};
const INVALID_STORAGE_TYPE = Error('Invalid storage type selected');

@Injectable()
export class StorageService {

  static observers: Object = {};

  private prefix = '';
  private type = STORAGE.local;

  static observe(type: STORAGE, key: string): EventEmitter<any> {
    const oKey = this.genObserverKey(type, key);
    if (oKey in this.observers)
      return this.observers[oKey];
    return this.observers[oKey] = new EventEmitter();
  }

  static emit(type: STORAGE, key: string, value: any): void {
    const oKey = this.genObserverKey(type, key);
    if (oKey in this.observers) this.observers[oKey].emit(value);
  }

  static genObserverKey(type: STORAGE, key: string): string {
    return type + '|' + key;
  }

  static getStorage(type: STORAGE): IWebStorage {
    switch (type) {
      case STORAGE.local:
        return localStorage;
      case STORAGE.session:
        return sessionStorage;
      default:
        throw INVALID_STORAGE_TYPE;
    }
  }

  constructor(private config: Configuration) {
    this.prefix = config.settings.appKey.toUpperCase();
  }

  public store(key: string, value: any, type?: STORAGE): void {

    type = type || this.type;

    const sKey = this.getKey(key);
    const storage = StorageService.getStorage(type);

    if (!value) {
      this.remove(sKey);
      return;
    }

    value = CoreService.isObject(value) || CoreService.isArray(value)
      ? JSON.stringify(value)
      : value;

    storage.setItem(sKey, value);

    STORAGECACHE[type][sKey] = value;

    StorageService.emit(type, sKey, value);
  }

  public retrieve(key: string, isObject: boolean = false, type?: STORAGE): any {

    type = type || this.type;

    const sKey = this.getKey(key);

    let value: any;

    value = STORAGECACHE[type][sKey];

    if (value) {
      return value;
    }

    value = this.retrieveFromStorage(key, isObject, type);

    if (value) {
      STORAGECACHE[type][sKey] = value;
      return value;
    }

    this.remove(sKey);

    return null;
  }

  private retrieveFromStorage(key: string, isObject: boolean = false, type?: STORAGE): any {

    type = type || this.type;

    const storage = StorageService.getStorage(type);
    const sKey = this.getKey(key);
    const item = storage.getItem(sKey);

    return isObject ? JSON.parse(item) : item;
  }

  public refresh(key: string, isObject: boolean = false, type?: STORAGE) {

    const value = this.retrieveFromStorage(key, isObject);
    const sKey = this.getKey(key);
    type = type || this.type;

    if (value === null) {
      delete STORAGECACHE[type][sKey];
      StorageService.emit(type, sKey, null);
    } else if (value !== STORAGECACHE[type][sKey]) {
      STORAGECACHE[type][sKey] = value;
      StorageService.emit(type, sKey, value);
    }
  }

  public clear(type?: STORAGE): void {

    const storage = StorageService.getStorage(type);

    Object.keys(storage)
      .forEach((key) => {
        storage.removeItem(key);
        delete STORAGECACHE[type][key];
        StorageService.emit(type, key, null);
      });
  }

  public remove(key: string, type?: STORAGE): void {

    type = type || this.type;

    const sKey = this.getKey(key);
    const storage = StorageService.getStorage(type);

    storage.removeItem(sKey);
    delete STORAGECACHE[type][sKey];
    StorageService.emit(type, sKey, null);
  }

  private getKey(key: string): string {
    return this.prefix ? this.prefix + ':' + key : key;
  }
}
